import React, { useEffect, useContext, useState } from "react";
import Sidebar from "../components/Sidebar";
import CardsList from "../components/CardsList";
import CreateCardForm from "../components/create-flashcard/CreateCardForm";
import { UserContext } from "../App";
import NewCreateCardForm from "../components/create-flashcard/NewCreateCardForm";
import { useNavigate } from 'react-router-dom';
import SkeletonSidebar from "../components/SkeletonSidebar";

const MyFlashcardsPage = () => {
	const navigate = useNavigate();
	const [createSetMode, setCreateSetMode] = useState(false);
	const [cardsSet, setCardsSet] = useState([
		{
			title: "Create a new set",
			_id: "0",
			onClick: () => setCreateSetMode(true),
		},
	]);

	const { user, fetchFlashcardSets, loadingUser } = useContext(UserContext);

	useEffect(() => {
		if (!loadingUser && !user) {
			navigate('/?openLogin=true');
			return;
		}

		if (user) {
			fetchFlashcardSets(user._id)
				.then((fetchedSets) => {
					if (fetchedSets) {
						setCardsSet([
							{
								title: "Create a new set",
								_id: "0",
								onClick: () => setCreateSetMode(true),
							},
							...fetchedSets,
						]);
					}
				});
		}
	}, [user, loadingUser, navigate, fetchFlashcardSets]);
	if (loadingUser) {
		// skeleton page using the Skeleton component
		return (
			<div className="">
				<SkeletonSidebar/>
				<main className="flex-1 py-10 px-5">
					<div className="lg:ml-72 border border-gray-200 rounded-xl p-5 shadow-sm mt-5">
						<div className="px-4 sm:px-6 lg:px-8">
							{/* Cards List Skeleton with adjusted styles */}
							<div className="space-y-4 py-2">
								{Array.from({length: 4}).map((_, index) => (
									<div key={index} className="flex animate-pulse">
										<div className="h-20 w-20 bg-gray-300 rounded m-5"></div>
										<div className="flex-1 space-y-3 py-2">
											<div className="h-4 bg-gray-300 rounded w-1/2 m-5"></div>
											<div className="h-4 bg-gray-300 rounded w-3/4 m-5"></div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</main>
			</div>
		)
	}

	if (createSetMode) {
		return (
			<div>
				<Sidebar/>
				<main className="lg:p-10">
					<div className="px-4 sm:px-6 lg:px-8 ">
						{/*<CreateCardForm*/}
						{/*	className="lg:ml-72 rounded-xl border border-gray-200 border-dashed bg-gray-50 "*/}
						{/*	setCreateSetMode={setCreateSetMode}*/}
						{/*/>*/}
						<NewCreateCardForm/>
					</div>
				</main>
			</div>
		);
	}

	return (
		<div>
			<Sidebar/>
			<main className="py-10 px-5">
				<div className="px-4 sm:px-6 lg:px-8 lg">
					<CardsList
						title="My Flashcard Sets"
						className="lg:pl-72"
						cardsSet={cardsSet}
					/>
				</div>
			</main>
		</div>
	);
};

export default MyFlashcardsPage;
