import React from 'react';
import SkeletonHeader from './SkeletonHeader'; // Import the SkeletonHeader component
import { FolderIcon, UsersIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";

const SkeletonSidebar = () => {
    return (
        <div>
            {/* Include SkeletonHeader at the top of SkeletonSidebar */}
            <SkeletonHeader />
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col bg-gray-900">
                <div className="flex grow flex-col overflow-y-auto px-6 pb-4">
                    {/* Real Logo and Title */}
                    <div className="flex h-16 items-center">
                        <img
                            className="h-9 w-auto"
                            src={`${process.env.PUBLIC_URL}/favicon.ico`}
                            alt="Quizify logo"
                        />
                        <div className="ml-3 text-white text-2xl">Quizify</div>
                    </div>
                    {/* Skeleton Navigation Items */}
                    <nav className="mt-5 flex-1">
                        <div className="space-y-1">
                            {/* Repeat this structure for each skeleton item you want to display */}
                            <div className="flex items-center p-2 space-x-4 animate-pulse">
                                <FolderIcon className="h-6 w-6 text-gray-400" />
                                <div className="flex-1 h-4 bg-gray-700 rounded"></div>
                            </div>
                            <div className="flex items-center p-2 space-x-4 animate-pulse">
                                <UsersIcon className="h-6 w-6 text-gray-400" />
                                <div className="flex-1 h-4 bg-gray-700 rounded"></div>
                            </div>
                            {/* Add more skeleton items as needed */}
                        </div>
                    </nav>
                    {/* Skeleton Footer/Settings */}
                    <div className="mt-auto flex items-center p-2 space-x-4 animate-pulse">
                        <Cog6ToothIcon className="h-6 w-6 text-gray-400" />
                        <div className="flex-1 h-4 bg-gray-700 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonSidebar;
