import React, {useContext, useEffect, useState} from "react";
import Sidebar from "../components/Sidebar";
import CardsListHub from "../components/CardsListHub";
import SkeletonHubPage from "./SkeletonHubPage";
import {UserContext} from "../App";

const FlashcardsHubPage = () => {
    const {fetchAllFlashcardSets} = useContext(UserContext);
    const [flashcardSets, setFlashcardSets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchAllFlashcardSets().then((fetchedSets) => {
            console.log("fetchedSets: ", fetchedSets);
            if (fetchedSets) {
                setFlashcardSets(fetchedSets);
            }
        }).then(() => setLoading(false));
    }, [fetchAllFlashcardSets]);

    if (!flashcardSets || loading) {
        return <SkeletonHubPage waitingForUser={false}/>;
    }

    return (
        <div>
            <Sidebar/>
            <main className="py-10 px-5">
                <div className="lg:ml-72 border border-gray-200 rounded-xl p-5 shadow-sm">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Flashcards Hub</h1>
                    </div>
                </div>

                <div className="lg:ml-72 border border-gray-200 rounded-xl p-5 shadow-sm mt-5">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <CardsListHub cardsSet={flashcardSets}/>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default FlashcardsHubPage;
