import React, {useEffect, useRef, useState,} from "react";
import Confetti from "react-confetti";
import ReactQuill from "react-quill";
import CardFileBtn from "../buttons/CardFileBtn";

const BackCard = (({
                       backText,
                       backFileName,
                       backFileUrl,
                       backColor,
                       isFlipped,
                       showConfetti,
                       // contentOverflow
                   }) => {

    const [isQuillLoaded, setIsQuillLoaded] = useState(false);
    const [mockFile, setMockFile] = useState({name: "google", url: "https://www.google.com"});
    const contentRef = useRef(null);

    useEffect(() => {
        if (!isFlipped) {
            // Load ReactQuill only if the card is active
            setIsQuillLoaded(true);
        }
    }, [isFlipped]);

    return (
        <div
            key="backCard"
            ref={contentRef}
            className={`card-back absolute inset-0 flex 
              rounded-lg overflow-auto
              `}
            style={{
                backfaceVisibility: "hidden",
                transform: "rotateY(-180deg)",
                whiteSpace: "pre-wrap",
                backgroundColor: backColor,
                display: 'grid',
                placeItems: 'center',
            }}
        >
            {showConfetti && isFlipped && <Confetti/>}

            {isQuillLoaded && (
                <ReactQuill
                    ref={contentRef}
                    value={backText}
                    readOnly={true} modules={{toolbar: false}}
                    className={`my-custom-quill
                    ${backFileUrl ? "mt-12" : ""}
                    `}

                />
            )}

            <CardFileBtn name={backFileName} url={backFileUrl}/>
            {/*<CardFileBtn className={"place-self-start"} name={mockFile.name} url={mockFile.url}/>*/}
        </div>
    )
});

const MemoizedBackCard = React.memo(BackCard);
export default MemoizedBackCard;