import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import MyFlashcardsPage from "./pages/MyFlashcardsPage";
import FlashcardsHubPage from "./pages/FlashcardsHubPage";
import LandingPage from "./pages/LandingPage";
import SignUpPage from "./pages/SignUpPage";
import Page404 from "./pages/Page404";
import AlertMsg from "./components/AlertMsg";
import PlayPage from "./pages/PlayPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import "react-quill/dist/quill.snow.css";
import SkeletonSidebar from "./components/SkeletonSidebar";
import SkeletonHubPage from "./pages/SkeletonHubPage";
import LoadingUserPage from "./pages/LoadingUserPage";
// import {ToastContainer} from "react-toastify";
import {ToastContainer, Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import "./App.css";

export const UserContext = React.createContext();


// const backendUrl = "http://localhost:3000";
const backendUrl = "https://quizify-backend-production.up.railway.app";

const fetchFlashcardSets = async (userId) => {
    try {
        const response = await fetch(
            `${backendUrl}/api/flashcard-sets/user/${userId}`,
            {
                method: "GET", // or 'POST'
                credentials: "include", // This is essential
                headers: {
                    "Content-Type": "application/json",
                    // Don't manually set the Authorization header here, as the cookie is automatically sent
                },
            }
        );
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    } catch (error) {
        console.error("Fetching flashcard sets failed:", error);
        return null;
    }
};


function App() {
    const [sideCurrent, setSideCurrent] = useState(0);
    const [user, setUser] = useState(null);
    const [alertMessage, setAlertMessage] = useState("");
    const [loadingUser, setLoadingUser] = useState(true);
    const [loadingHub, setLoadingHub] = useState(true);
    const [myFlashcardSets, setMyFlashcardSets] = useState([]);
    // default jwt is from url params, when the url is '/?token=...'
    const [jwt, setJwt] = useState(localStorage.getItem("jwt") || "");


    // Function to show the alert
    const showAlert = (msg) => {
        setAlertMessage(msg);
    };

    // Function to close the alert
    const closeAlert = () => {
        setAlertMessage("");
    };

    /**
     * Checks if the JWT is valid based on its 'exp' claim.
     *
     * @param {string} jwt The JWT to validate.
     * @return {boolean} True if the JWT is valid, false otherwise.
     */
    const isJwtValid = (jwt) => {
        if (!jwt) return false;

        try {
            const base64Url = jwt.split('.')[1]; // Get the payload part
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convert base64url to base64
            const payload = JSON.parse(atob(base64));
            const now = Date.now() / 1000; // Convert to seconds

            return payload.exp > now;
        } catch (error) {
            console.error("Error decoding JWT", error);
            return false;
        }
    };

    // In your App component or a similar initialization point
    useEffect(() => {
        const token = localStorage.getItem("jwt");
        const isValidUser = isJwtValid(token);
        if (token && isValidUser) {
            const usr = localStorage.getItem("user");
            if (usr) {
                setUser(JSON.parse(usr));
            }
        } else {
            localStorage.removeItem("jwt");
            localStorage.removeItem("user");
        }
        setLoadingUser(false); // Indicate that user loading process is complete
    }, []);


    useEffect(() => {
        if (
            user &&
            (window.location.pathname === "/" ||
                window.location.pathname === "/signup")
        ) {
            // fetch flashcard sets and then redirect to dashboard

            fetchFlashcardSets(user._id) // Assuming `user.id` holds the user's ID
                .then((fetchedSets) => {
                    console.log("fetchedSets: ", fetchedSets);
                    if (fetchedSets) {
                        setMyFlashcardSets(fetchedSets);
                    }
                })
        }
        setLoadingUser(false);
    }, [user]);

    const handleLogin = (userData, tokenData) => {
        if (tokenData && userData) {
            setJwt(tokenData);
            localStorage.setItem("jwt", tokenData);
            setUser(userData);
            localStorage.setItem("user", JSON.stringify(userData));
        } else {
            setJwt("");
            localStorage.removeItem("jwt");
            setUser(null);
            localStorage.removeItem("user");
            localStorage.clear()
        }
    }

    const afterLogin = (data) => {
        // localStorage.setItem("user", JSON.stringify(data));
        // setUser(data);
        // console.log("data: ", data);
        // console.log("data.token: ", data.token);
        // setJwt(data.token);
        window.location.href = data.token ? `/loading-user/${data.token}` : "/";
    };


    const fetchAllFlashcardSets = async () => {
        try {
            const response = await fetch(
                `${backendUrl}/api/flashcard-sets/`
            );
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            setLoadingHub(false)
            return response.json();
        } catch (error) {
            console.error("Fetching flashcard sets failed:", error);
            return null;
        }
    };


    return (
        <UserContext.Provider
            value={{
                sideCurrent,
                setSideCurrent,
                user,
                afterLogin,
                alertMessage,
                setAlertMessage,
                showAlert,
                closeAlert,
                loadingUser,
                fetchFlashcardSets,
                myFlashcardSets,
                fetchAllFlashcardSets,
                jwt,
                backendUrl,
                setLoadingUser,
                handleLogin
            }}
        >
            <ToastContainer
                position="top-center" // Positions the toast notification on the top right corner
                draggable // Allows the toast to be dragged and dismissed
                theme="dark" // Sets the theme, can be 'light', 'dark', or 'colored'
            />

            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={<LandingPage/>}
                    />
                    <Route
                        path="/signup"
                        element={<SignUpPage setUser={afterLogin}/>}
                    />
                    <Route
                        path="/dashboard"
                        element={<DashboardPage/>}
                    />
                    <Route
                        path="/flashcards/my-cards"
                        element={<MyFlashcardsPage/>}
                    />
                    <Route
                        path="/flashcards/hub"
                        element={<FlashcardsHubPage/>}
                    />
                    <Route
                        path="/flashcards/:sid"
                        element={<PlayPage/>}
                    />
                    {/* 404 page: <Page404> elem */}
                    <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicyPage/>}
                    />

                    {/*<Route*/}
                    {/*	path="test"*/}
                    {/*	element={<SkeletonHubPage />}*/}
                    {/*/>*/}

                    <Route
                        path='/loading-user/:token'
                        element={<LoadingUserPage/>}
                    />
                    <Route
                        path="*"
                        element={<Page404/>}
                    />
                    {/* <Route
						path="*"
						element={<Navigate to={"/"} />}
					/> */}
                    {/* ...other routes... */}
                </Routes>
            </Router>
            <AlertMsg
                message={alertMessage}
                onClose={closeAlert}
            />
        </UserContext.Provider>
    );
}

export default App;
