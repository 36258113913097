import React, {useState} from "react";
import FlashCard from "../flashcard/FlashCard";
import {wait} from "@testing-library/user-event/dist/utils";


export default function CompletionScreen({name, description, flashcards, cardSetId, onReset}) {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFinish =  () => {
        window.location.href = `${process.env.PUBLIC_URL}/flashcards/${cardSetId}`
        // await wait(1000);
        onReset();
    }


    // show small flashcard set preview in list of flashcard
    return (
        <div className="p-2 grid grid-cols-2 gap-4"
        >
            {/*<div className="alert alert-success mb-10" role="alert">*/}
            {/*    <h2 className="text-lg font-medium text-gray-900">Flashcard set created</h2>*/}
            {/*    <p className="text-sm text-gray-500">Your flashcard set has been created successfully.</p>*/}
            {/*</div>*/}


            <div className="mb-10 col-span-2 sm:col-span-1">
                <h2 className="text-lg font-medium text-gray-900">Flashcard set details</h2>
                <p className="text-sm text-gray-500">Name: {name}</p>
                <p className="text-sm text-gray-500">Description: {description}</p>
            </div>


            <div
                className="col-span-2 sm:col-span-1 alert alert-info mb-10 flex flex-col justify-center items-center sm:items-end"
                role="alert">
                {/*// lets play!!*/}
                {/*btn -> play in: '/flashcards/:id'*/}
                {/*<button*/}
                {/*    onClick={() => window.location.href = `${process.env.PUBLIC_URL}/flashcards/${cardSetId}`}*/}
                {/*    className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">*/}
                {/*</button>*/}

                <button
                    type="button"
                    className="sm:w-40 w-32
                    rounded-full bg-indigo-600
                    px-4 py-2.5 text-sm font-semibold
                     text-white
                     shadow-sm ring-1 ring-inset ring-gray-300
                      hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={handleFinish}
                >
                    Let's start
                </button>


            </div>


            <div className="col-span-2">
                <div className="alert alert-info mb-10" role="alert">
                    <h2 className="text-lg font-medium text-gray-900">Flashcards</h2>
                    <p className="text-sm text-gray-500">Here are the flashcards in your set.</p>
                </div>
                <div className="grid grid-cols-1 xxl:grid-cols-s">
                    <hr/>
                    {flashcards.map((flashcard, index) => (
                        <div key={index}>
                            <div className="m-5 mb-0 text-sm font-medium text-gray-700">
                                {index + 1} / {flashcards.length}
                            </div>
                            <FlashCard
                                className="m-2 p-4 transform transition duration-500 hover:scale-105 w-fit-content"
                                // sm:my-5 md:my-16
                                // key={index}
                                frontText={flashcard.frontText}
                                backText={flashcard.backText}
                                backFile={flashcard.backFile}
                                backColor={flashcard.backColor}
                                frontColor={flashcard.frontColor}
                                disableFeedback={true}
                            />
                            <hr/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}


// import {border} from "@mui/system";
// import React, {useState, useEffect, useRef} from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.bubble.css";
// import Confetti from "react-confetti";
//
// const Flashcard = ({
//                        className,
//                        frontText,
//                        backText,
//                        handleNext,
//                        handlePrev,
//                        prev,
//                        next,
//                        cardHeight,
//                        cardWidth,
//                        backColor,
//                        frontColor,
//                        backFileName,
//                        backFileUrl,
//                        isDraggableMode,
//                        handleBorderColor,
//                        disableFeedback,
//                        isShouldFlip,
//                        setIsShouldFlipped
//                    }) => {
//     // State to track items dropped on the back of the flashcard
//     // const [droppedItems, setDroppedItems] = useState([
//     // DraggableTextBox({ id: "1", content: "Draggable text box" }),
//     // DraggableImage({ id: "2", src: backFileUrl || `${process.env.PUBLIC_URL}/favicon.ico`}),
//     // ]);
//
//     // Handler for dropped items
//     // const handleDrop = (item) => {
//     //   setDroppedItems(currentItems => [...currentItems, item]);
//     // };
//
//     // Define the drop zone only for the back side of the card
//     // const [{ isOver }, drop] = useDrop(() => ({
//     //   accept: ["TEXT_BOX", "IMAGE"],
//     //   drop: handleDrop,
//     //   collect: monitor => ({
//     //     isOver: !!monitor.isOver(),
//     //   }),
//     // }), [setDroppedItems]);
//
//     const [feedbackButtons, setFeedbackButtons] = useState([
//         {id: 3, title: "Not yet", color: '#C82333', textColor: 'text-white'},
//         // {id: 2, title: 'I don\'t know', color: '#FF8C00', textColor: 'text-white'},
//         {id: 1, title: 'Got it', color: '#00C851', textColor: 'text-white'},
//     ]);
//     const [showConfetti, setShowConfetti] = useState(false);
//
//     const [isFlipped, setIsFlipped] = useState(false);
//     const [actionType, setActionType] = useState(null); // Tracks which action to perform
//     const [borderColor, setBorderColor] = useState("")
//     const [isPulsing, setIsPulsing] = useState(false);
//
//     useEffect(() => {
//         if (isShouldFlip !== undefined && isShouldFlip !== isFlipped) {
//             setIsShouldFlipped(isFlipped);
//         }
//     }, [isFlipped, isShouldFlip, setIsShouldFlipped]);
//
//     useEffect(() => {
//         if (handleBorderColor) {
//             setBorderColor("");
//         }
//     }, [handleBorderColor]);
//     const wait = (duration = 150) => new Promise(resolve => setTimeout(resolve, duration));
//
//     // Example function to handle feedback button click
//     const handleFeedback = (buttonId, color) => {
//         // Assuming your green button has an id of 1 for demonstration
//         setBorderColor(color)
//         console.log(color)
//         setIsPulsing(true);
//         setTimeout(() => setIsPulsing(false), 750); // Reset after animation
//         if (buttonId === 1) { // Check if the green button is clicked based on its id
//             setShowConfetti(true);
//             setTimeout(() => setShowConfetti(false), 4000); // Turn off confetti after 2 seconds
//         } else {
//             setShowConfetti(false);
//         }
//     };
//     const handleAction = async (action) => {
//         setIsFlipped(false); // Assume this initiates the flip animation
//
//         await wait(); // Wait for the "animation" to "complete"
//
//         // Now that the "animation" is "complete", proceed with the action
//         if (action === 'prev') {
//             handlePrev();
//         } else if (action === 'next') {
//             handleNext();
//         }
//     };
//     // useEffect(() => {
//     //     if (actionType) {
//     //         // const timer = setTimeout(() => {
//     //             if (actionType === 'prev') {
//     //                 handlePrev();
//     //             } else if (actionType === 'next') {
//     //                 handleNext();
//     //             }
//     //             setActionType(null); // Reset action type to avoid re-execution
//     //         // }, 3000); // Adjust this delay as needed
//     //         // return () => clearTimeout(timer); // Cleanup timeout
//     //     }
//     // }, [actionType, handleNext, handlePrev]); // Effect runs when `actionType` changes
//
//     // const handleAction = (type) => {
//     //     setIsFlipped(false);
//     //     setActionType(type); // Set the action type to 'prev' or 'next'
//     // };
//
//     const frontCardRef = useRef(null);
//     const backCardRef = useRef(null);
//
//     // Apply the drop ref only if isDraggableMode is true and the card is flipped
//     // useEffect(() => {
//     //   if(isDraggableMode && isFlipped) {
//     //     drop(backCardRef.current);
//     //   }
//     // }, [isDraggableMode, isFlipped, drop]);
//
//     const [contentOverflow, setContentOverflow] = useState({
//         front: false,
//         back: false,
//     });
//
//     useEffect(() => {
//         setContentOverflow({
//             front:
//                 frontCardRef.current?.scrollHeight > frontCardRef.current?.clientHeight,
//             back:
//                 backCardRef.current?.scrollHeight > backCardRef.current?.clientHeight,
//         });
//     }, [frontText, backText, backColor]); // Rerun when text changes
//
//     return (
//         <>
//             {/* Render the Confetti component when showConfetti is true */}
//             <div className='flex flex-col'>
//                 <div
//                     className={`flex items-center justify-center ${className}`}
//                     style={{perspective: "1000px"}}
//                 >
//
//
//                     {/* Omitted for brevity, including navigation arrows */}
//                     {prev ? (
//                         <div
//                             className="flex justify-center items-center cursor-pointer w-8 h-8 sm:w-20 sm:h-20"
//                             onClick={() => handleAction('prev')}
//                             // onClick={() => {
//                             //     setIsFlipped(false);
//                             //     handlePrev();
//                             //     setFlipTrigger(true);
//                             //     // setTimeout(() => {
//                             //     //     handlePrev();
//                             //     // }, 500);
//                             // }}
//                         >
//                             <svg
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 width="64"
//                                 height="64"
//                                 fill="currentColor"
//                                 className="bi bi-chevron-left"
//                                 viewBox="0 0 16 16"
//                             >
//                                 <path
//                                     fillRule="evenodd"
//                                     d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
//                                 />
//                             </svg>
//                         </div>
//                     ) : (
//                         <div className="flex justify-center items-center w-8 h-8 sm:w-20 sm:h-20">
//                             <div className="w-[64px] h-[64px]"></div>
//                         </div>
//                     )}
//                     <div
//                         className={`card mb-5 ${
//                             cardHeight ? cardHeight : "h-[22rem] sm:h-[25rem] md:h-[30rem]"
//                         } ${
//                             cardWidth
//                                 ? cardWidth
//                                 : "w-[40rem] sm:w-[43rem] md:w-[30rem] lg:w-[40rem] xl:w-[50rem]"
//                         } ${borderColor !== "" ? `border-[${borderColor}] bo border-2` : ""
//                         }
//                         transition-transform duration-500 relative shadow-lg border rounded-lg
//                         ${isPulsing ? 'pulse-grow' : ''}
//                         `}
//                         style={{
//                             transform: isFlipped ? "rotateY(180deg)" : "",
//                             transformStyle: "preserve-3d",
//                             // pulse with the borderColor
//                             borderColor: borderColor,
//                         }}
//                         onClick={() => setIsFlipped(!isFlipped)}
//                     >
//
//                         {/* Front side */}
//                         <div
//                             key="frontCard"
//                             className={`card-front absolute inset-0 flex ${
//                                 contentOverflow.front ? "items-start" : "items-center"
//                             } justify-center rounded-lg  overflow-auto`}
//                             ref={frontCardRef}
//                             style={{
//                                 backfaceVisibility: "hidden",
//                                 whiteSpace: "pre-wrap",
//                                 backgroundColor: isFlipped ? "" : frontColor,
//                             }}
//                         >
//                             {showConfetti && !isFlipped && <Confetti/>}
//                             {/* {frontText} */}
//                             <ReactQuill value={frontText} readOnly={true} modules={{toolbar: false}}
//                                         className="my-custom-quill"
//                                         defaultValue={" "}/>
//
//                         </div>
//                         {/* Back side */}
//                         <div
//                             key="backCard"
//                             className={`card-back absolute inset-0 flex ${
//                                 contentOverflow.back ? "items-start" : "items-center"
//                             } justify-center rounded-lg overflow-auto `}
//                             ref={backCardRef}
//                             style={{
//                                 backfaceVisibility: "hidden",
//                                 transform: "rotateY(180deg)",
//                                 whiteSpace: "pre-wrap",
//                                 backgroundColor: isFlipped ? backColor : "",
//                             }}
//                         >
//                             {showConfetti && isFlipped && <Confetti/>}
//                             {/* Render dropped items here */}
//                             {/* {droppedItems.map((item, index) => (
//             <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//               {item.type === "TEXT_BOX" ? <DraggableTextBox {...item} /> : null}
//               {item.type === "IMAGE" ? <DraggableImage {...item} /> : null}
//             </div>
//           ))} */}
//                             {/* <div className="">{backText}</div> */}
//                             <ReactQuill value={backText} readOnly={true} modules={{toolbar: false}}
//                                         className="my-custom-quill"/>
//
//
//                             {/* Download link, if applicable */}
//                             {backFileName && backFileUrl && (
//                                 <a
//                                     href={backFileUrl}
//                                     download
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                     onClick={(e) => e.stopPropagation()}
//                                     className=" absolute top-3 right-3 cursor-pointer
//                bg-white bg-opacity-50 p-2
//                shadow-lg rounded-lg
//                 border-gray-300
//                 hover:bg-opacity-100 transition duration-300 ease-in-out"
//                                 >
//                                     {/* Download icon and filename */}
//                                     <div className="flex items-center">
//                                         <svg
//                                             xmlns="http://www.w3.org/2000/svg"
//                                             width="16"
//                                             height="16"
//                                             fill="currentColor"
//                                             className="bi bi-download h-6 w-6 mr-2"
//                                             viewBox="0 0 16 16"
//                                         >
//                                             <path
//                                                 d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
//                                             <path
//                                                 d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
//                                         </svg>
//                                         <span>{backFileName}</span>
//                                     </div>
//                                 </a>
//                             )}
//                         </div>
//                     </div>
//                     {next ? (
//                         <div
//                             className="flex justify-center items-center cursor-pointer w-8 h-8 sm:w-20 sm:h-20"
//                             onClick={() => handleAction('next')}
//
//                             // onClick={() => {
//                             //     setIsFlipped(false);
//                             //     setFlipTrigger(true);
//                             //     // setTimeout(() => {
//                             //     //     handleNext();
//                             //     // }, 500); // 1000 milliseconds = 1 seconds
//                             // }}
//                         >
//                             <svg
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 width="64"
//                                 height="64"
//                                 fill="currentColor"
//                                 className="bi bi-chevron-right"
//                                 viewBox="0 0 16 16"
//                             >
//                                 <path
//                                     fillRule="evenodd"
//                                     d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
//                                 />
//                             </svg>
//                         </div>
//                     ) : (
//                         <div className="flex justify-center items-center w-8 h-8 sm:w-20 sm:h-20">
//                             <div className="w-[64px] h-[64px]"></div>
//                         </div>
//                     )}
//                 </div>
//                 <div className="flex  space-x-6
//                  justify-center mt-4">
//                     {!disableFeedback && feedbackButtons.map((button) => (
//                         <button
//                             style={{backgroundColor: button.color}}
//                             type={"button"}
//                             key={button.id}
//                             className={`${button.textColor} px-24 text-center py-2 rounded shadow hover:scale-105 transition-transform `}
//                             onClick={() => handleFeedback(button.id, button.color)}
//                         >
//                             {button.title}
//                         </button>
//                     ))}
//                 </div>
//
//             </div>
//         </>
//     )
//         ;
// };
//
// export default Flashcard;
