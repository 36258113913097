import React from "react";
import SkeletonElement from "./SkeletonElement";
import SkeletonSidebar from "../components/SkeletonSidebar";

// Privacy Policy page
// the privacy policy is in html in the public folder and is displayed in an iframe

function Post({ loading, title, content }) {
    return (
        <div>
            {loading ? (
                <React.Fragment>
                    <SkeletonElement type="title" />
                    <SkeletonElement type="text" />
                    <SkeletonElement type="text" />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h1>{title}</h1>
                    <p>{content}</p>
                </React.Fragment>
            )}
        </div>
    );
}



const PrivacyPolicyPage = () => {
    return (
        <div
        className="border border-gray-200 rounded-xl shadow-sm m-20"
        >
            <iframe
                title="Privacy Policy"
                src="/privacy.html"
                width="100%"
                height="1000px"
                className="rounded-xl"
            />

            {/*<Post loading={true} />*/}
            {/*<SkeletonSidebar />*/}
        </div>
    );
}

export default PrivacyPolicyPage;