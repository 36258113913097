import React, {useState, useContext, useEffect} from "react";
import SetDetailsForm from "./SetDetailsForm";
import CardCreationForm from './CardCreationForm'
import CompletionScreen from "./CompletionScreen";
import Circles from "./Circles";
import {UserContext} from "../../App";
import {wait} from "@testing-library/user-event/dist/utils";
import {toast} from 'react-toastify';

export default function NewCreateCardForm({className}) {
    const [currentStep, setCurrentStep] = useState(1);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [flashcards, setFlashcards] = useState([]);
    const [cardsCount, setCardsCount] = useState(0);
    const [cardSetId, setCardSetId] = useState(null);
    const {user, backendUrl} = useContext(UserContext);

    useEffect(() => {
        if (!user) {
            window.location.href = "/login";
        }
        const localSetId = localStorage.getItem('cardSetId');
        if (localSetId && !cardSetId) {
            setCardSetId(cardSetId);
            fetch(`${backendUrl}/api/flashcard-sets/${localSetId}`)
                .then((response) => response.json())
                .then((data) => {
                    setName(data.title);
                    setDescription(data.description);
                    setCardSetId(data._id);
                    setFlashcards(data.flashcards);
                });
        } else if (currentStep > 0 && !cardSetId) {
            setCurrentStep(0);
        }
        if (flashcards.length > 0) setCardsCount(flashcards.length);
    }, [user, backendUrl, currentStep, flashcards, cardSetId]);


    const postFlashcardSet = async (data) => {
        // like in the ref, but only creating the empty set
        try {
            const setResponse = await fetch(
                `${backendUrl}/api/flashcard-sets`,
                {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        uid: user._id,
                        title: data.name,
                        description: data.description,
                    }),
                }
            );

            if (!setResponse.ok) {
                throw new Error("Problem creating flashcard set");
            }

            const setResponseData = await setResponse.json();
            setCardSetId(setResponseData._id);
            console.log("setResponseData:", setResponseData);
            localStorage.setItem('cardSetId', setResponseData._id);
        } catch (error) {
            console.error("Error:", error);
            // Handle error
        }
    }

    const postFlashcard = async (data) => {
        // like in the loop inside the ref, with all data, but only one card
        try {
            if (!cardSetId) {
                console.error("No card set id, NewCreateCardForm.js:150");
                throw new Error("No card set id");
            }
            console.log("cardSetId:", cardSetId)
            const formData = new FormData();
            formData.append('setId', cardSetId);
            formData.append('uid', user._id);
            formData.append('flashcardData', JSON.stringify({
                frontText: data.frontText,
                backText: data.backText,
                backBgColor: data.backColor,
                frontBgColor: data.frontColor
            }));

            // Check if there's a file to upload and append it to formData
            if (data.backFile) {
                formData.append('backFile', data.backFile);
            }

            const cardResponse = await fetch(
                `${backendUrl}/api/flashcard-sets/add-card`,
                {
                    method: "POST",
                    body: formData, // Send formData instead of JSON
                    // Do not set Content-Type header, the browser will set it with the proper boundary
                }
            );

            if (!cardResponse.ok) {
                throw new Error("Problem adding card to set");
            }
        } catch (error) {
            console.error("Error:", error);
            // Handle error
        }

    }

    // post new set and moving to step 2
    const handleSubmitSetDetails = (data) => {
        if (!data.name) {
            toast.error("Name is required");
        }
        setName(data.name);
        setDescription(data.description);
        postFlashcardSet(data).then(() => setCurrentStep(1));
    }

    // post new card and moving to step 3
    const handleSubmitCard = (data, afterSubmit) => {
        setFlashcards([...flashcards, data]);
        postFlashcard(data)
            .then(() => {
                setCardsCount(cardsCount + 1);
                afterSubmit();
                toast.success("Card added")
                // alert("Card added")
            })
        // .then(() => setCurrentStep(2));
    }

    const handleFinish = () => {
        setCurrentStep(2)
    }

    const onReset = () => {
        setName("");
        setDescription("");
        setFlashcards([]);
        setCardsCount(0);
        localStorage.removeItem('cardSetId');
        localStorage.removeItem('cardSetName');
        localStorage.removeItem('cardSetDescription');
        setCardSetId(null);
        setCurrentStep(0);
    }


    // const onReset = () => {
    //     setCurrentStep(0);
    //     setName("");
    //     setDescription("");
    //     setFlashcards([]);
    //     setCardsCount(0);
    //     setCardSetId(null);
    //     localStorage.removeItem('cardSetId');
    // }


    return (
        <>
            {/*// step counter*/}
            <Circles
                className="lg:ml-72 flex justify-center"
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                shouldGoTo2={cardSetId && name}
                shouldGoTo3={cardsCount > 0}
            />

            {/*// form*/}
            <div className={`lg:ml-72 lg:px-20 pt-2 mt-2`}>
                {currentStep === 0 && (
                    <SetDetailsForm
                        onSubmit={handleSubmitSetDetails}
                        // onReset={onReset}
                    />
                )}
                {currentStep === 1 && (
                    <CardCreationForm
                        onSubmit={handleSubmitCard}
                        onFinish={handleFinish}
                        // onReset={onReset}
                        numberOfCards={cardsCount}
                    />
                )}
                {currentStep === 2 && <CompletionScreen
                    flashcards={flashcards}
                    name={name}
                    description={description}
                    cardSetId={cardSetId}
                    onReset={onReset}
                />}
            </div>
        </>
    );
}

