import React, {useState, useContext} from "react";
import {UserContext} from "../App";
import {toast} from 'react-toastify';


const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {afterLogin, alertMessage, setAlertMessage, showAlert, closeAlert, backendUrl, notify} =
        useContext(UserContext);
    const handleLogin = async (event) => {
        event.preventDefault(); // Prevent the default form submit action
        if (email === "" || password === "") {
            showAlert("Please fill in all the fields");
            return;
        }
        try {
            const response = await fetch(
                // "https://quizify-backend-production.up.railway.app/api/users/login",
                `${backendUrl}/api/users/login`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password,
                    }),
                    credentials: "include", // This line is crucial
                }
            );
            const data = await response.json();
            if (response.ok) {
                // Handle successful login here
                // console.log("Login successful", data);
                toast.success("Login successful");
                afterLogin(data);
            } else {
                // Handle errors here
                // setAlertMessage(data.message);
                toast.error(data.message);
                // alert(data.message);
                // notify();
                console.error("Login failed", data.message);
            }
        } catch (error) {
            // alert("invalid credentials");
            toast.error("Invalid credentials");
            console.error("Network error", error);
        }
    };
    return (
        <div className="relative sm:col-span-2 md:col-span-1 xl:col-span-6">
            <div
                className="relative h-full w-full rounded-xl
                 bg-zinc-900  shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)]  before:pointer-events-none  before:absolute
                 before:-inset-px  before:rounded-xl  before:shadow-[0px_2px_8px_0px_rgba(0,_0,_0,_0.20),_0px_1px_0px_0px_rgba(255,_255,_255,_0.06)_inset]
                forced-colors:outline p-10 m-5">
                <div
                    className="grid h-full w-full overflow-hidden place-items-start justify-items-center p-6 py-8 sm:p-8 lg:p-12">
                    <form
                        className="w-full max-w-sm space-y-8"
                        onSubmit={handleLogin}
                    >
                        <h3 className="text-lg/7 font-semibold tracking-[-0.015em] sm:text-base/7  text-white">
                            Log in
                        </h3>

                        <div
                            className="[&>*+[data-slot=control]]:mt-6 [&>[data-slot=text]]:mt-1"
                            role="group"
                            data-headlessui-state=""
                        >
                            {/* Username Input */}
                            <div
                                data-slot="control"
                                className="space-y-8"
                            >
                                <div
                                    data-headlessui-state=""
                                    className="[&>[data-slot=label]+[data-slot=control]]:mt-3 [&>[data-slot=label]+[data-slot=description]]:mt-1 [&>[data-slot=description]+[data-slot=control]]:mt-3 [&>[data-slot=control]+[data-slot=description]]:mt-3 [&>[data-slot=control]+[data-slot=error]]:mt-3 [&>[data-slot=label]]:font-medium"
                                >
                                    <label
                                        htmlFor="email"
                                        className="select-none text-base/6  data-[disabled]:opacity-50 sm:text-sm/6  text-white"
                                        id="username-label"
                                    >
                                        Email
                                    </label>
                                    <input
                                        className="relative block w-full appearance-none rounded-lg
                                        px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)]
                                        text-base/6 text-zinc-950 placeholder:text-zinc-500
                                        border border-zinc-950/10 focus:outline-none"
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        aria-labelledby="email-label"
                                    />
                                </div>
                            </div>
                            {/* Password Input */}
                            <div
                                data-slot="control"
                                className="space-y-8"
                            >
                                <div
                                    data-headlessui-state=""
                                    className="[&>[data-slot=label]+[data-slot=control]]:mt-3
                                    [&>[data-slot=label]+[data-slot=description]]:mt-1
                                    [&>[data-slot=description]+[data-slot=control]]:mt-3
                                     [&>[data-slot=control]+[data-slot=description]]:mt-3
                                      [&>[data-slot=control]+[data-slot=error]]:mt-3 [&>[data-slot=label]]:font-medium"
                                >
                                    <label
                                        htmlFor="password"
                                        className="select-none text-base/6 data-[disabled]:opacity-50 sm:text-sm/6  text-white"
                                        id="password-label"
                                    >
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        className="relative block w-full appearance-none rounded-lg
                                        px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)]
                                        text-base/6 text-zinc-950 placeholder:text-zinc-500 border border-zinc-950/10
                                        focus:outline-none"
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        aria-labelledby="password-label"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Additional Form Components */}
                        {/* ... */}
                        {/* Additional Form Components */}
                        <div className="flex flex-wrap justify-between gap-2">
                            {/* Remember Me Toggle */}
                            {/* <div
							className="flex items-center gap-2"
							data-headlessui-state=""
						>
							<button
								type="button"
								className="group relative isolate inline-flex h-6 w-10 cursor-default rounded-full p-[3px] sm:h-5 sm:w-8 transition duration-0 ease-in-out data-[changing]:duration-200 forced-colors:outline forced-colors:[--switch-bg:Highlight]  forced-colors:[--switch-bg:Highlight] bg-zinc-200 ring-1 ring-inset ring-black/5  bg-white/5  ring-white/15 data-[checked]:bg-[--switch-bg] data-[checked]:ring-[--switch-bg-ring]  data-[checked]:bg-[--switch-bg]  data-[checked]:ring-[--switch-bg-ring] focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500 data-[hover]:data-[checked]:ring-[--switch-bg-ring] data-[hover]:ring-black/15  data-[hover]:data-[checked]:ring-[--switch-bg-ring]  data-[hover]:ring-white/25 data-[disabled]:bg-zinc-200 data-[disabled]:data-[checked]:bg-zinc-200 data-[disabled]:opacity-50 data-[disabled]:data-[checked]:ring-black/5  data-[disabled]:bg-white/15  data-[disabled]:data-[checked]:bg-white/15  data-[disabled]:data-[checked]:ring-white/15"
								id="remember-me-toggle"
								role="switch"
								aria-checked="false"
								aria-labelledby="remember-me-label"
							>
								<span
									aria-hidden="true"
									className="pointer-events-none relative inline-block size-[1.125rem] rounded-full sm:size-3.5 translate-x-0 transition duration-200 ease-in-out border border-transparent bg-white shadow ring-1 ring-black/5 group-data-[checked]:bg-[--switch] group-data-[checked]:shadow-[--switch-shadow] group-data-[checked]:ring-[--switch-ring] group-data-[checked]:translate-x-4 sm:group-data-[checked]:translate-x-3 group-data-[disabled]:group-data-[checked]:bg-white group-data-[disabled]:group-data-[checked]:shadow group-data-[disabled]:group-data-[checked]:ring-black/5"
								></span>
							</button>
							<label
								htmlFor="remember-me-toggle"
								className="select-none text-base/6 text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6  text-white"
								id="remember-me-label"
							>
								Remember <span className="hidden sm:inline">me</span>
							</label>
						</div> */}
                            {/* Forgot Password Link */}
                            {/* <p className="text-base/6 text-zinc-500 sm:text-sm/6  text-zinc-400">
							<a
								href="#"
								className="font-semibold text-zinc-950 hover:text-zinc-700  text-white  hover:text-zinc-300"
							>
								Forgot password?
							</a>
						</p> */}
                        </div>
                        {/* Sign In Button */}

                        <button
                            className="w-full relative isolate inline-flex items-center justify-center gap-x-2
                            rounded-lg border text-base/6 font-semibold px-[calc(theme(spacing[3.5])-1px)]
                            py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing.3)-1px)]
                            sm:py-[calc(theme(spacing[1.5])-1px)] sm:text-sm/6 focus:outline-none
                            border-transparent bg-blue-500 hover:bg-blue-600 focus:bg-blue-700
                            focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500
                             text-white cursor-pointer"
                            type="submit"
                        >
                            Get started
                            <span
                                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 hidden sm:block"
                                aria-hidden="true"
                            ></span>
                        </button>

                        {/* "Sign in using Google" Button with a more noticeable hover effect */}
                        <button
                            type="button"
                            className="mt-4 w-full inline-flex items-center justify-center gap-x-2 rounded-lg bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-gray-700 font-semibold px-4 py-2 shadow-sm transition duration-150 ease-in-out border border-gray-300"
                            onClick={() => window.location.href = `${backendUrl}/auth/google`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 128 128">
                                <path fill="#fff"
                                      d="M44.59 4.21a63.28 63.28 0 004.33 120.9 67.6 67.6 0 0032.36.35 57.13 57.13 0 0025.9-13.46 57.44 57.44 0 0016-26.26 74.33 74.33 0 001.61-33.58H65.27v24.69h34.47a29.72 29.72 0 01-12.66 19.52 36.16 36.16 0 01-13.93 5.5 41.29 41.29 0 01-15.1 0A37.16 37.16 0 0144 95.74a39.3 39.3 0 01-14.5-19.42 38.31 38.31 0 010-24.63 39.25 39.25 0 019.18-14.91A37.17 37.17 0 0176.13 27a34.28 34.28 0 0113.64 8q5.83-5.8 11.64-11.63c2-2.09 4.18-4.08 6.15-6.22A61.22 61.22 0 0087.2 4.59a64 64 0 00-42.61-.38z"/>
                                <path fill="#e33629"
                                      d="M44.59 4.21a64 64 0 0142.61.37 61.22 61.22 0 0120.35 12.62c-2 2.14-4.11 4.14-6.15 6.22Q95.58 29.23 89.77 35a34.28 34.28 0 00-13.64-8 37.17 37.17 0 00-37.46 9.74 39.25 39.25 0 00-9.18 14.91L8.76 35.6A63.53 63.53 0 0144.59 4.21z"/>
                                <path fill="#f8bd00"
                                      d="M3.26 51.5a62.93 62.93 0 015.5-15.9l20.73 16.09a38.31 38.31 0 000 24.63q-10.36 8-20.73 16.08a63.33 63.33 0 01-5.5-40.9z"/>
                                <path fill="#587dbd"
                                      d="M65.27 52.15h59.52a74.33 74.33 0 01-1.61 33.58 57.44 57.44 0 01-16 26.26c-6.69-5.22-13.41-10.4-20.1-15.62a29.72 29.72 0 0012.66-19.54H65.27c-.01-8.22 0-16.45 0-24.68z"/>
                                <path fill="#319f43"
                                      d="M8.75 92.4q10.37-8 20.73-16.08A39.3 39.3 0 0044 95.74a37.16 37.16 0 0014.08 6.08 41.29 41.29 0 0015.1 0 36.16 36.16 0 0013.93-5.5c6.69 5.22 13.41 10.4 20.1 15.62a57.13 57.13 0 01-25.9 13.47 67.6 67.6 0 01-32.36-.35 63 63 0 01-23-11.59A63.73 63.73 0 018.75 92.4z"/>
                            </svg>
                            Sign in with Google
                        </button>


                        {/* Sign Up Link */}
                        <p className="text-base/6 sm:text-sm/6  text-zinc-400">
                            Don’t have an account?{" "}
                            <a
                                href="/signup"
                                className="font-semibold   text-white  hover:text-zinc-300"
                            >
                                Sign up
                            </a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
