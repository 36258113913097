import React from "react";
import SkeletonSidebar from "../components/SkeletonSidebar"; // Ensure the import path matches your project structure

const SkeletonHubPage = () => {
    return (
        <div className="">
            <SkeletonSidebar />
            <main className="flex-1 py-10 px-5">
                {/* Layout structure mimicking FlashcardsHubPage */}
                <div className="lg:ml-72 border border-gray-200 rounded-xl p-5 shadow-sm">
                    <div className="px-4 sm:px-6 lg:px-8">
                        {/* Keep the original title */}
                        <h1 className="text-3xl font-bold text-gray-900">Flashcards Hub</h1>
                    </div>
                </div>

                <div className="lg:ml-72 border border-gray-200 rounded-xl p-5 shadow-sm mt-5">
                    <div className="px-4 sm:px-6 lg:px-8">
                        {/* Cards List Skeleton with adjusted styles */}
                        <div className="space-y-4 py-2">
                            {Array.from({ length: 4 }).map((_, index) => (
                                <div key={index} className="flex animate-pulse">
                                    <div className="h-20 w-20 bg-gray-300 rounded m-5"></div>
                                    <div className="flex-1 space-y-3 py-2">
                                        <div className="h-4 bg-gray-300 rounded w-1/2 m-5"></div>
                                        <div className="h-4 bg-gray-300 rounded w-3/4 m-5"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default SkeletonHubPage;
