import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { sets } from "../Data";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function CardsList({ className, title, cardsSet = sets }) {
	return (
		<div className={className}>
			<h2 className="text-sm font-medium text-gray-500">{title}</h2>
			<ul
				role="list"
				className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
			>
				{cardsSet.map((set, i) =>
					set._id === "0" ? (
						<li
							key={i}
							// className="overflow-hidden rounded-xl border border-gray-200 border-dashed bg-gray-50 justify-center items-center flex hover:bg-gray-100 hover:border-gray-300 transition-colors duration-200 hover:shadow-lg cursor-pointer hover:scale-105"
							className="overflow-hidden rounded-xl border border-gray-200 border-dashed bg-gray-50 justify-center items-center flex text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 cursor-pointer hover:scale-105"
							onClick={set.onClick}
						>
							<div className="flex items-center gap-x-4 p-6">
								<div className="text font-bold font-mclaren">{set.title}</div>
							</div>
						</li>
					) : (
						<li
							key={i}
							className="overflow-hidden m-1 rounded-xl border-gray-300 border-2 ring-2 ring-gray-900/5
							shadow-sm cursor-pointer   bg-[#f5f5f5] focus:outline-none focus:ring-2 focus:ring-indigo-500
							 focus:ring-offset-2 hover:shadow-lg hover:scale-105 transition-all duration-200 hover:ring-0
							 hover:border-gray-400"
							onClick={() =>
								(window.location.href = `${process.env.PUBLIC_URL}/flashcards/${set._id}`)
							}
						>
							<div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-[#ffffff] p-7">
								<div className="text-sm font-medium leading-6 text-gray-900">
									{set.title}
								</div>
								<Menu
									as="div"
									className="relative ml-auto"
								>
									<Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
										<span className="sr-only">Open options</span>
										<EllipsisHorizontalIcon
											className="h-5 w-5"
											aria-hidden="true"
										/>
									</Menu.Button>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											<Menu.Item>
												{({ active }) => (
													<a
														href="#" // Link to view the flashcard set
														className={classNames(
															active ? "bg-gray-50" : "",
															"block px-3 py-1 text-sm leading-6 text-gray-900"
														)}
													>
														View
														<span className="sr-only">, {set.title}</span>
													</a>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<a
														href="#" // Link to edit the flashcard set
														className={classNames(
															active ? "bg-gray-50" : "",
															"block px-3 py-1 text-sm leading-6 text-gray-900"
														)}
													>
														Edit
														<span className="sr-only">, {set.title}</span>
													</a>
												)}
											</Menu.Item>
											{/* Additional menu items can be added here */}
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
							<dl className="-my-3 divide-y divide-gray-200 px-6 py-4 text-sm leading-6">

								{/* <div className="flex justify-between gap-x-4 py-3">
									<dt className="text-gray-500">Date Created</dt>

									<dd className="text-gray-700">
										<time
											dateTime={
												set.dateCreated
													? new Date(set.dateCreated).toISOString()
													: undefined
											}
										>
											{set.dateCreated
												? new Date(set.dateCreated).toLocaleDateString()
												: "N/A"}
										</time>
									</dd>
								</div> */}
								<div className="flex justify-between gap-x-4 py-3">
									<dt className="text-gray-500">made by</dt>
									<dd className="text-gray-700">
										{/* made by uid */}
										{set.creatorId == "65986af3bba2d5e391ae5ac4" ? "Quizify" : "Anonymous"}
									</dd>
								</div>
								<div className="flex justify-between gap-x-4 py-3">
									<dt className="text-gray-500">Description</dt>
									<dd className="text-gray-700">{set.description}</dd>
								</div>
							</dl>
						</li>
					)
				)}
			</ul>
		</div>
	);
}
