import React, {useContext, useState, useEffect} from "react";
import FlashCard from "../flashcard/FlashCard";
import {UserContext} from "../../App";
import ReactQuill from "react-quill";
import { SliderPicker } from 'react-color';

import "react-quill/dist/quill.snow.css";


/**
 * CardCreationForm is a form to create a new flashcard.
 * It contains fields for the front and back of the flashcard.
 * It also contains a button to submit the form.
 * It does not contain the form tag itself.
 * It is intended to be used within a form tag.
 * It is intended to be used within the NewCreateCardForm component.
 * It is using parts of the code from the CreateCardForm component.
 *
 * @param {function} onSubmit - The function to call when the form is submitted.
 * @param {function} onFinish - The function to call when the form is finished.
 * @param numberOfCards - The number of cards in the set.
 */
export default function CardCreationForm({onSubmit, onFinish, numberOfCards, onReset}) {
    const [isFlipped, setIsFlipped] = useState(false);
    const [frontText, setFrontText] = React.useState("");
    const [backText, setBackText] = React.useState("");

    const [backFile, setBackFile] = React.useState(null);
    const [backColor, setBackColor] = React.useState("#ffffff");
    const [frontColor, setFrontColor] = React.useState("#ffffff");

    const [isSubmitted, setIsSubmitted] = useState(false);

    // useEffect(() => {
    //     if (!localStorage.getItem("cardSetId")) {
    //         onFinish();
    //     }
    // }, [onFinish]);

    useEffect(() => {
        if (isSubmitted) {
            setFrontText("");
            setBackText("");
            setBackFile(null);
            // setBackColor("#ffffff");
            // setFrontColor("#ffffff");
            setIsFlipped(false);
            setIsSubmitted(false);
        }
    }, [isSubmitted]);

    const handleSubmit = (event) => {
        event.preventDefault();

        onSubmit({frontText, backText, backFile, backColor, frontColor}, () => setIsSubmitted(true));
    }

    const modules = {
        toolbar: {
            container: [
                [{'size': ['small', false, 'large', 'huge']},
                    {'direction': 'rtl'},
                    "bold", "italic", "underline", "strike",
                    {align: []}, {list: "ordered"}, {list: "bullet"},
                    {'color': []}, {'background': []}, {font: []},
                    {'indent': '-1'}, {'indent': '+1'}
                ],
                ["link", "image", "video", "code-block", "blockquote",
                    {'script': 'sub'}, {'script': 'super'},],
            ],
        },
    };


    return (
        <div className="lg:px-10">
            <div className="">
                {/*max cards is 30*/}
                <h2 className="text-lg font-medium text-gray-900 font-mclaren">{numberOfCards + 1} <span
                    className=" text-gray-500">/ 50</span></h2>
            </div>
            <div className="grid grid-cols-2
             gap-4 p-5 pt-1 rounded-xl">
                {/* Flashcard Preview */}
                {/* Flashcard Preview */}
                <div className="col-span-2 flex items-center justify-center"
                    // onClick={() => setIsFlipped(!isFlipped)}
                >
                    <FlashCard
                        className="mt-5 p-5 pt-1"
                        cardHeight={"h-[22rem] sm:h-[25rem] md:h-[30rem]"}
                        cardWidth={
                            "w-[20rem] sm:w-[23rem] md:w-[25rem] lg:w-[30rem] xl:w-[35rem]"
                        }
                        frontText={frontText}
                        backText={backText}
                        backColor={backColor}
                        frontColor={frontColor}
                        backFileName={backFile ? backFile.name : ""}
                        backFileUrl={backFile ? URL.createObjectURL(backFile) : ""}
                        file={backFile}
                        afterFlip={() => setIsFlipped(!isFlipped)}
                        handleBorderColor={isSubmitted}
                        numberOfCards={numberOfCards}
                    />
                </div>

                <div className=" col-span-2">

                    {isFlipped ? (
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Back Side</label>
                            <ReactQuill
                                key="backEditor"
                                theme="snow"
                                value={backText}
                                readOnly={false}
                                onChange={(e) => setBackText(e)}
                                modules={modules}
                            />
                            <div className="mt-2 text-sm text-gray-500 block  w-[12.5%]">
                                <span>Background Color:</span>
                                <SliderPicker
                                    color={backColor}
                                    onChangeComplete={(color) => setBackColor(color.hex)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Front Side</label>
                            <ReactQuill
                                key="frontEditor"
                                theme="snow"
                                value={frontText}
                                readOnly={false}
                                onChange={(e) => setFrontText(e)}
                                modules={modules}
                            />
                            <div className="mt-2 text-sm text-gray-500 block  w-[12.5%]">
                                <span>Background Color:</span>
                                <SliderPicker
                                    color={frontColor}
                                    onChangeComplete={(color) => setFrontColor(color.hex)}
                                />
                            </div>
                        </div>
                    )}
                    <input
                        key={`backFile-${numberOfCards}`}
                        type="file"
                        onChange={(e) => {
                            setBackFile(e.target.files[0]);
                        }}
                        className="mt-2 block w-full text-sm text-gray-500 ms-[-7px]
                                   file:mr-4 file:rounded-full file:border-0 file:bg-indigo-50 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-indigo-700
                                   hover:file:bg-indigo-100"
                    />
                </div>

                <div className="col-span-1 flex justify-end">
                    <button
                        type="submit"
                        className="w-1/2 inline-flex justify-center rounded-md border border-transparent
                     bg-indigo-600 py-2 px-4 border-indigo-600 text-base font-medium text-white
                     hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2
                     focus:ring-indigo-500 sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {numberOfCards < 49 ? "Add Card" : "Add Card and Finish"}
                    </button>
                </div>
                <div className="col-span-1 flex   justify-start">
                    <button
                        type="submit"
                        className="w-1/2 inline-flex justify-center rounded-md border border-transparent
                     bg-indigo-600 py-2 px-4 border-indigo-600 text-base font-medium text-white
                     hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2
                     focus:ring-indigo-500 sm:text-sm"
                        onClick={onFinish}
                    >
                        Finish
                    </button>
                </div>
            </div>
        </div>
    )
        ;
}