import {CheckIcon} from '@heroicons/react/20/solid'

const steps = [
    {name: 'Step 1', href: '#', status: 'complete'},
    {name: 'Step 2', href: '#', status: 'complete'},
    {name: 'Step 3', href: '#', status: 'current'},
    {name: 'Step 4', href: '#', status: 'upcoming'},
    {name: 'Step 5', href: '#', status: 'upcoming'},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Circles({currentStep, setCurrentStep, className, shouldGoTo2, shouldGoTo3}) {

    const handleClick = (i) => {
        console.log(i)
        if (i === 0) {
            setCurrentStep(i);
        }
        if (i === 1 && shouldGoTo2) {
            setCurrentStep(i);
        }
        if (i === 2 && shouldGoTo3 && shouldGoTo2) {
            setCurrentStep(i);
        }
    }


    return (
        <div className={`${className} mx-auto flex justify-center py-6`}>
            <nav aria-label="Progress">
                <ol role="list" className="flex items-center">
                    {Array.from({length: 3}).map((_, i) => (
                        <li key={i} className={classNames(i !== 2 ? 'pr-8 sm:pr-20' : '', 'relative')}>
                            {i < currentStep ? (
                                <>
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="h-0.5 w-full bg-indigo-600"/>
                                    </div>
                                    <button
                                        onClick={() => handleClick(i)}
                                        className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900"
                                    >
                                        <CheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                                        <span className="sr-only">{`step ${i}`}</span>
                                    </button>
                                </>
                            ) : i === currentStep ? (
                                <>
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="h-0.5 w-full bg-gray-200"/>
                                    </div>
                                    <button
                                        onClick={() => handleClick(i)}
                                        className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                                        aria-current="step"
                                    >
                                        <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true"/>
                                        <span className="sr-only">{`step ${i}`}</span>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="h-0.5 w-full bg-gray-200"/>
                                    </div>
                                    <button
                                        onClick={() => handleClick(i)}
                                        className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                                    >
                  <span
                      className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                      aria-hidden="true"
                  />
                                        <span className="sr-only">{`step ${i}`}</span>
                                    </button>
                                </>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    )
}
