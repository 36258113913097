import React from 'react';

export default function PrevCardBtn({onClick, enabled}) {
    return (
        <>
            {enabled ? (
                <div
                    className="flex justify-center items-center cursor-pointer w-8 h-8 sm:w-20 sm:h-20"
                    onClick={onClick}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="64"
                        height="64"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                    </svg>
                </div>
            ) : (
                <div className="flex justify-center items-center w-8 h-8 sm:w-20 sm:h-20">
                    <div className="w-[64px] h-[64px]"></div>
                </div>
            )}
        </>
    )
}