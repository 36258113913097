// import { SelectorIcon } from "@heroicons/react/solid";
//You're trying to import `@heroicons/react/solid/SelectorIcon` from Heroicons v1 but have installed Heroicons v2. Install `@heroicons/react@v1` to resolve this error.

// import { SelectorIcon } from "@heroicons/react/solid";

// import { ChevronDownIcon } from "@heroicons/react/solid";
import PieChartWithCustomizedLabel from "./PieChart";

const people = [
	{
		name: "Lindsay Walton",
		title: "Front-end Developer",
		email: "lindsay.walton@example.com",
		role: "Member",
	},
	// More people...
	{
		name: "Courtney Henry",
		title: "Designer",
		email: "abc@example.com",
		role: "Admin",
	},
	{
		name: "Tom Cook",
		title: "Director, Product Development",
		email: "abcd@example.com",
		role: "Admin",
	},
	{
		name: "Whitney Francis",
		title: "Copywriter",
		email: "abcde@example.com",
		role: "Member",
	},
];

const graphs = [1, 2, 3];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Example() {
	return (
		<div className=" ">
			<h2 className="text-sm font-medium text-gray-500 pb-3">Pinned Cards</h2>
			<div className="sm:grid grid-cols-3 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 sm:gap-4">
				{graphs.map((graph, i) => (
					<div
						className="border rounded-md shadow-sm p-5 bg-white"
						style={{ minWidth: '250px' }}
						key={i}
					>
						<h2 className="text font-medium text-gray-500">Statistics</h2>
						<p className="mt-2 text-sm font-bold text-gray-700">
							{/* Cards statistics for the last 30 days */}
							Chart
							<PieChartWithCustomizedLabel />
						</p>
					</div>
				))}

				{/* <div className="sm:flex-auto border rounded-lg p-5 shadow">
					<h2 className="text font-medium text-gray-500">Statistics</h2>
					<p className="mt-2 text-sm font-bold text-gray-700">
						Cards statistics for the last 30 days
					</p>
				</div> */}

				{/* <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
					<label
						htmlFor="filter"
						className="sr-only"
					>
						Filter
					</label>
					<div className="relative">
						<select
							id="filter"
							name="filter"
							className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
							defaultValue="Last 30 days"
						>
							<option>Last 30 days</option>
							<option>Last 6 months</option>
							<option>Last year</option>
						</select>
					</div>
				</div> */}

				{/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
					<button
						type="button"
						className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						Add user
					</button>
				</div> */}
			</div>
			<div className="flow-root p-10">
				<div className="mt-10 -mx-4 -my-2 sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle">
						<table className="min-w-full border-separate border-spacing-0">
							<thead>
								<tr>
									<th
										scope="col"
										className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
									>
										Name
									</th>
									<th
										scope="col"
										className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
									>
										Title
									</th>
									<th
										scope="col"
										className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
									>
										Email
									</th>
									<th
										scope="col"
										className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
									>
										Role
									</th>
									<th
										scope="col"
										className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
									>
										<span className="sr-only">Edit</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{people.map((person, personIdx) => (
									<tr key={person.email}>
										<td
											className={classNames(
												personIdx !== people.length - 1
													? "border-b border-gray-200"
													: "",
												"whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
											)}
										>
											{person.name}
										</td>
										<td
											className={classNames(
												personIdx !== people.length - 1
													? "border-b border-gray-200"
													: "",
												"whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
											)}
										>
											{person.title}
										</td>
										<td
											className={classNames(
												personIdx !== people.length - 1
													? "border-b border-gray-200"
													: "",
												"whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell"
											)}
										>
											{person.email}
										</td>
										<td
											className={classNames(
												personIdx !== people.length - 1
													? "border-b border-gray-200"
													: "",
												"whitespace-nowrap px-3 py-4 text-sm text-gray-500"
											)}
										>
											{person.role}
										</td>
										<td
											className={classNames(
												personIdx !== people.length - 1
													? "border-b border-gray-200"
													: "",
												"relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8"
											)}
										>
											<a
												href="#"
												className="text-indigo-600 hover:text-indigo-900"
											>
												Edit<span className="sr-only">, {person.name}</span>
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
