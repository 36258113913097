import React from 'react';
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";

const SkeletonHeader = () => {
    return (
        <div className="lg:pl-72">
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                {/* Hamburger Icon - Visible on small screens */}
                <div className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </div>

                {/* Search Box Placeholder */}
                <div className="relative flex flex-1">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="h-4 w-4 bg-gray-300 rounded-full animate-pulse"></span>
                    </div>
                    <div className="block w-full bg-gray-200 rounded-md h-10 animate-pulse"></div>
                </div>

                {/* Notification Icon Placeholder */}
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                    <div className="-m-2.5 p-2.5 rounded-full bg-gray-200 h-10 w-10 animate-pulse"></div>

                    {/* User Avatar Placeholder */}
                    <div className="h-10 w-10 rounded-full bg-gray-200 animate-pulse"></div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonHeader;
