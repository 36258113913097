import React, {useState, useContext, useEffect} from 'react';
import {UserContext} from '../../App';

const FeedbackButtons = ({feedbackButtons, onFeedback, cardId, disableFeedback, on404, on400Submit}) => {
    const {user, backendUrl, jwt} = useContext(UserContext);
    const [feedbackFetched, setFeedbackFetched] = useState(false);


    // fetching the feedback if already exists
    useEffect(() => {
        const fetchFeedback = async () => {
            if (!user || !cardId || !jwt || feedbackFetched) {
                return;
            }
            try {
                const response = await fetch(
                    `${backendUrl}/api/flashcard-status/get-status`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: jwt,
                            uid: user.id || user._id,
                            fid: cardId,
                        }),
                    }
                );

                if (response.status === 400) {
                    setFeedbackFetched(true);
                    return on400Submit();
                }

                // handle 404 (404 != error)
                if (response.status === 404) {
                    setFeedbackFetched(true)
                    on404()
                    return;
                }

                if (!response.ok) {
                    throw new Error('Problem fetching feedback');
                }
                const data = await response.json();
                console.log('fetchFeedback', data);

                if (data.status) {
                    onFeedback(data.status, feedbackButtons.find((button) => button.title === data.status).color, false)
                }
                setFeedbackFetched(true)
            } catch (error) {
                // Handle error (e.g., show an error message)
                alert('Problem fetching feedback')
            }
        };
        fetchFeedback();
    }, [user, cardId, backendUrl, jwt, onFeedback, feedbackButtons, feedbackFetched, on404]);


    // Function to call the API and submit feedback
    const submitFeedback = async (userId, cardId, feedbackType) => {
        if (disableFeedback) {
            return;
        }
        if (!userId || !cardId || !feedbackType) {
            // Handle error (e.g., show an error message)
            alert('Invalid data')
            return;
        }
        if (!jwt) {
            // Handle error (e.g., show an error message)
            alert('User not authenticated')
            return;
        }

        console.log('submitFeedback', userId, cardId, feedbackType, jwt)
        try {
            const response = await fetch(`${backendUrl}/api/flashcard-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: userId,
                    fid: cardId,
                    status: feedbackType,
                    token: jwt
                }),
            });


            if (response.status === 400) {
                setFeedbackFetched(true);
                return on400Submit();
            }

            // handle 404 (404 != error)
            if (response.status === 404) {
                setFeedbackFetched(true);
                return on404();
            }

            if (!response.ok) {
                throw new Error('Problem submitting feedback');
            }

            // Handle success (e.g., show a success message)
            // alert('Feedback submitted successfully')

        } catch (error) {
            // Handle error (e.g., show an error message)
            alert('Problem submitting feedback')
        }
    };

// In your Flashcard component, when a feedback button is clicked
    const handleFeedbackClick = (button, cardId, feedbackType) => {
        if (!user) {
            alert('Please login to submit feedback')
            return;
        }
        const userId = user.id || user._id || null;
        if (!userId) {
            // Handle error (e.g., show an error message)
            alert('User ID not found')
            return;
        }
        onFeedback(button.id, button.color)
        submitFeedback(userId, cardId, feedbackType)
    };


    return (
        <div className="flex space-x-6 sm:space-x-6 justify-center my-6"
             key={cardId}>
            {feedbackButtons.map((button) => (
                <button
                    style={{backgroundColor: button.color}}
                    type="button"
                    key={button.id}
                    className={`${button.textColor} px-10 sm:px-24 text-center py-2 rounded shadow hover:scale-105 transition-transform `}
                    onClick={() => handleFeedbackClick(button, cardId, button.title)}
                >
                    {button.title}
                </button>
            ))}
        </div>
    );
};

export default FeedbackButtons;
