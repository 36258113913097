import React from "react";

export default function CardFileBtn({ className, name, url }) {
    return (
        <div className={className}>
            {name && url && (
                <a
                    href={url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    className="absolute top-3 right-3 cursor-pointer bg-white
                                    bg-opacity-50 p-2 shadow-lg rounded-lg border-gray-300
                                    hover:bg-opacity-100 transition duration-300 ease-in-out"
                >
                    {/* Download icon and filename */}
                    <div className="flex items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-download h-6 w-6 mr-2"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                            <path
                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                        </svg>
                        <span>
                            {name.length > 15 ? name.slice(0, 15) + "..." : name}
                        </span>
                    </div>
                </a>
            )}
        </div>
    )
}