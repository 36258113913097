import React, {useState, useEffect} from "react";
import FeedbackButtons from "../buttons/FeedbackButtons";
import PrevCardBtn from "../buttons/PrevCardBtn";
import NextCardBtn from "../buttons/NextCardBtn";
import useFlip from './useFlip';
import FrontCard from "./FrontCard";
import BackCard from "./BackCard";
// import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";

const Flashcard = React.memo(({
                                  className, cardId, cardHeight, cardWidth,
                                  handleNext, handlePrev, prev, next,
                                  handleBorderColor, isPlayMode, numberOfCards,
                                  afterFlip,
                                  frontText, backText, backFileName, backFileUrl,
                                  backColor = "#f5f5f5", frontColor = "#f5f5f5",
                              }) => {

    // <----- initialize states and refs ------>
    const [feedbackButtons, setFeedbackButtons] = useState([
        {id: 3, title: "Not yet", color: '#C82333', textColor: 'text-white'},
        {id: 1, title: 'Got it', color: '#00C851', textColor: 'text-white'},
    ]);
    const [showConfetti, setShowConfetti] = useState(false);
    const [borderColor, setBorderColor] = useState("")
    const [isPulsing, setIsPulsing] = useState(false);
    // const [contentOverflow, setContentOverflow] = useState({frontText: false, backText: false});
    const {isFlipped, toggleFlip, shouldAnimate} = useFlip(numberOfCards);
    // const frontCardRef = useRef(null);
    // const backCardRef = useRef(null);
    // <---------->


    // <----- useEffects ------>
    // useEffect(() => {
    //     console.log("Front Text: ", frontCardRef.current?.scrollHeight > frontCardRef.current?.clientHeight)
    //     console.log("Back Text: ", backCardRef.current?.scrollHeight > backCardRef.current?.clientHeight)
    //     setContentOverflow({
    //         frontText:
    //             frontCardRef.current?.scrollHeight > frontCardRef.current?.clientHeight,
    //         backText:
    //             backCardRef.current?.scrollHeight > backCardRef.current?.clientHeight,
    //     });
    // }, [frontText, backText, backColor]); // Rerun when text changes

    useEffect(() => {
        if (handleBorderColor) {
            setBorderColor("");
        }
    }, [handleBorderColor]);
    // <---------->

    // <----- Functions ------>
    const wait = (duration = 150) => new Promise(resolve => setTimeout(resolve, duration));
    const handleFeedback = (buttonId, color, shouldPulsing = true) => {
        console.log("handleFeedback called", { buttonId, color, shouldPulsing });

        setBorderColor(color)
        console.log(color)
        if (shouldPulsing) {
            setIsPulsing(true);
            wait(750).then(() => setIsPulsing(false));
        }
        if (buttonId === 1) {
            setShowConfetti(true);
            wait(4000).then(() => setShowConfetti(false));
        } else {
            setShowConfetti(false);
        }
        // Removed any call to toggleFlip() here to ensure it doesn't flip unintentionally
    };


    const handleAction = async (action) => {
        // setIsFlipped(false); // Assume this initiates the flip animation
        // if (afterFlip) afterFlip();
        // await wait(); // Wait for the "animation" to "complete"
        if (isFlipped) {
            toggleFlip();
            // await wait(600); // Wait for the "animation" to "complete"
            if (afterFlip) afterFlip();
            // await wait(450); // Wait for the "animation" to "complete"
        }
        // Now that the "animation" is "complete", proceed with the action
        if (action === 'prev') {
            handlePrev();
        } else if (action === 'next') {
            handleNext();
        }
        handleFeedback(-1, "", false)

    };
    // <---------->


    return (
        <div key={cardId}>
            <div className='flex flex-col'>
                <div
                    className={`flex items-center justify-center ${className}`}
                    style={{perspective: "1000px"}}
                >
                    <PrevCardBtn onClick={() => handleAction('prev')} enabled={prev}/>
                    <div
                        className={`card mb-5 transition-transform relative shadow-lg border rounded-lg
                        ${cardHeight ? cardHeight : "h-[22rem] sm:h-[25rem] md:h-[30rem]"}
                        ${cardWidth ? cardWidth : "w-[40rem] sm:w-[43rem] md:w-[30rem] lg:w-[40rem] xl:w-[50rem]"} 
                        ${borderColor && "border-4"}
                        ${isPulsing && isFlipped && "animate-pulse-grow-flip"}
                        ${isPulsing && !isFlipped && "animate-pulse-grow"}
                        `}
                        style={{
                            transform: `${isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)'}`,
                            transformStyle: "preserve-3d",
                            borderColor: borderColor,
                            // transition: "transform 0.6s",
                            background: `linear-gradient(50deg, ${frontColor}, ${backColor})`,
                            transition: shouldAnimate ? "transform 0.6s" : "none", // Apply transition only if shouldAnimate is true
                        }}
                        onClick={() => {
                            toggleFlip();
                            if (afterFlip) afterFlip();
                        }}
                    >
                        {/* Front side */}
                        <FrontCard
                            frontText={frontText}
                            frontColor={frontColor}
                            isFlipped={isFlipped}
                            showConfetti={showConfetti}
                        />
                        {/* Back side */}
                        <BackCard
                            backText={backText}
                            backFileName={backFileName}
                            backFileUrl={backFileUrl}
                            backColor={backColor}
                            isFlipped={isFlipped}
                            showConfetti={showConfetti}
                        />
                    </div>
                    <NextCardBtn onClick={() => handleAction('next')} enabled={next}/>
                </div>

                {/* <-----Feedback Buttons-----> */}
                {isPlayMode && (
                    <FeedbackButtons
                        onFeedback={handleFeedback}
                        feedbackButtons={feedbackButtons}
                        cardId={cardId}
                        disableFeedback={!isPlayMode}
                        on404={() => {
                            setBorderColor("")
                        }}
                        on400Submit={() => {
                            // should log in again
                            setBorderColor("");
                            localStorage.clear();
                            window.location.reload();
                        }}
                    />
                )}
                {/* <----------> */}

            </div>
        </div>
    );
});

export default Flashcard;
