import { XCircleIcon } from '@heroicons/react/20/solid'

function AlertMsg({ message, onClose }) {
	// The component will not render anything if no message is provided
	if (!message) return null;

	return (
		<div className="rounded-md bg-red-50 p-4">
			<div className="flex">
				<div className="flex-shrink-0">
					<XCircleIcon
						className="h-5 w-5 text-red-400"
						aria-hidden="true"
					/>
				</div>
				<div className="ml-3">
					<h3 className="text-sm font-medium text-red-800">{message}</h3>
				</div>
				<div className="ml-auto pl-3">
					<button
						onClick={onClose}
						className="rounded-md text-red-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
					>
						<span className="sr-only">Close</span>
						<XCircleIcon
							className="h-5 w-5"
							aria-hidden="true"
						/>
					</button>
				</div>
			</div>
		</div>
	);
}

export default AlertMsg;
