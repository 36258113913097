import React, { useContext, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import PinnedSets from "../components/PinnedSets";
import CardsList from "../components/CardsList";
import DashTable from "../components/DashTable";
import { UserContext } from "../App";

const DashboardPage = () => {
	const { user } = useContext(UserContext);
	useEffect(() => {
	   if (!user) {
		  window.location.href = "/";
	   }
	}, [user]);
	
	return (
		<div>
			<Sidebar />
			<main className="py-10 px-5">
				<div
					className="lg:ml-72 border border-gray-200 rounded-xl p-5 shadow-sm"
					// style={{
					//     backgroundImage: "radial-gradient(circle, grey 0.5px, transparent 0.5px)",
					//     backgroundSize: "20px 20px",
					//     backgroundPosition: "-4px -4px",
					// }}
				>
					<div className="px-4 sm:px-6 lg:px-8">
						<PinnedSets className="mt-5 p-1" />
					</div>
					<div className="px-4 sm:px-6 lg:px-8 mt-20">
						<DashTable />
					</div>
					<div className="px-4 sm:px-6 lg:px-8 mt-20">
						<CardsList />
					</div>
				</div>
			</main>
		</div>
	);
};

export default DashboardPage;
