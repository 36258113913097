import React, {useEffect, useRef, useState} from "react";
import Confetti from "react-confetti";
import ReactQuill from "react-quill";

const FrontCard = (({frontText, frontColor, isFlipped, showConfetti}) => {


    const [isQuillLoaded, setIsQuillLoaded] = useState(false);
    const [animationClass, setAnimationClass] = useState("");
    const contentRef = useRef(null);

    useEffect(() => {
        if (!isFlipped) {
            // Load ReactQuill only if the card is active
            setIsQuillLoaded(true);
        }
    }, [isFlipped]);

    useEffect(() => {
        setAnimationClass('animate-text-fade-in'); // Apply the fade-in animation
        const timer = setTimeout(() => setAnimationClass(''), 500); // Reset animation class after it's done
        return () => clearTimeout(timer);
    }, [frontText]);


    return (
        <div
            key={"frontCard"}
            ref={contentRef}
            className={`
            card-front absolute inset-0 flex 
            rounded-lg overflow-auto 
            ${animationClass}
            `}
            style={{
                backfaceVisibility: "hidden",
                whiteSpace: "pre-wrap",
                backgroundColor: frontColor,
                display: 'grid',
                placeItems: 'center',
            }}
        >
            {showConfetti && !isFlipped && <Confetti/>}

            {isQuillLoaded && (
                <ReactQuill
                    value={frontText}
                    readOnly={true}
                    modules={{toolbar: false}}
                    className="my-custom-quill"
                    defaultValue={" "}
                />
            )}

        </div>
    )
});

const MemoizedFrontCard = React.memo(FrontCard);

export default MemoizedFrontCard;