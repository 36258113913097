const pinnedSets = [
	{
		name: "Algorithms",
		initials: "Alg",
		href: `${process.env.PUBLIC_URL}/flashcards/algorithms`,
		cards: 16,
		bgColor: "bg-pink-600",
	},
	{
		name: "Data Structures",
		initials: "DS",
		href: `${process.env.PUBLIC_URL}/flashcards/data-structures`,
		cards: 12,
		bgColor: "bg-purple-600",
	},
	{
		name: "Operating Systems",
		initials: "OS",
		href: `${process.env.PUBLIC_URL}/flashcards/operating-systems`,
		cards: 16,
		bgColor: "bg-yellow-500",
	},
	{
		name: "Databases",
		initials: "DB",
		href: `${process.env.PUBLIC_URL}/flashcards/databases`,
		cards: 8,
		bgColor: "bg-green-500",
	},
];


const sets = [
	{
		id: 1,
		name: "Algorthims",
		// imageUrl: "https://tailwindui.com/img/logos/48x48/tuple.svg",
        bgColor: "bg-pink-600",
        initials: "Alg",
		lastInvoice: {
			date: "December 13, 2022",
			dateTime: "2022-12-13",
			amount: "$2,000.00",
			status: "Overdue",
		},
	},
    {
        id: 2,
        name: "Data Structures",
        // imageUrl: "https://tailwindui.com/img/logos/48x48/reform.svg",
        bgColor: "bg-purple-600",
        initials: "DS",
        lastInvoice: {
            date: "January 23, 2023",
            dateTime: "2023-01-23",
            amount: "$7,600.00",
            status: "Paid",
        },
    },
    {
        id: 3,
        name: "Operating Systems",
        // imageUrl: "https://tailwindui.com/img/logos/48x48/workcation.svg",
        bgColor: "bg-yellow-500",   
        initials: "OS",
        lastInvoice: {
            date: "January 23, 2023",
            dateTime: "2023-01-23",
            amount: "$7,600.00",
            status: "Paid",
        },
    },
	{
		id: 4,
		name: "Databases",
		// imageUrl: "https://tailwindui.com/img/logos/48x48/savvycal.svg",
        bgColor: "bg-green-500",
        initials: "DB",
		lastInvoice: {
			date: "January 22, 2023",
			dateTime: "2023-01-22",
			amount: "$14,000.00",
			status: "Paid",
		},
	},
    // {
    //     id: 5,
        
    // }
];

export { pinnedSets, sets };