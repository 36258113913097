import React, { useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SkeletonHubPage from "./SkeletonHubPage";
import { UserContext } from "../App";

const LoadingUserPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { jwt, backendUrl, setLoadingUser, user, handleLogin } = useContext(UserContext);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!token) {
                setLoadingUser(false);
                return navigate('/');
            }
            try {
                const response = await fetch(`${backendUrl}/api/users/me`, {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify({ token }),
                    headers: { "Content-Type": "application/json" },
                });

                if (response.ok) {
                    const userData = await response.json();
                    handleLogin(userData, token);
                    localStorage.setItem("user", JSON.stringify(userData));
                    setLoadingUser(false);
                    navigate("/flashcards/hub");
                } else {
                    throw new Error("User not authenticated");
                }
            } catch (error) {
                console.error(error);
                localStorage.clear();
                handleLogin(null, null);
                setLoadingUser(false);
                navigate('/');
            }
        };
        fetchUserData();
    }, [token, backendUrl, navigate, handleLogin, setLoadingUser]);

    return <SkeletonHubPage />;
};

export default LoadingUserPage;
