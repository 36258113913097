import React, {useState, useEffect, useContext} from "react";
import Flashcard from "../components/flashcard/FlashCard";
import Sidebar from "../components/Sidebar";
import {useParams, useNavigate} from "react-router-dom"; // useNavigate instead of useHistory
import {UserContext} from "../App";
import SkeletonElement from "./SkeletonElement";

const PlayPage = () => {
    const {sid} = useParams();
    const navigate = useNavigate(); // Use useNavigate hook
    const [flashcardSet, setFlashcardSet] = useState([]);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [currIndex, setCurrIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [isFlipped, setIsFlipped] = useState(false);

    const {backendUrl} = useContext(UserContext);


    useEffect(() => {
        const fetchFlashcardSet = async () => {
            if (!sid || !backendUrl) {
                return;
            }
            try {
                const response = await fetch(
                    `${backendUrl}/api/flashcard-sets/${sid}`
                );
                if (!response.ok) {
                    throw new Error("Flashcard set not found or error fetching data");
                }
                const data = await response.json();
                console.log("data:", data);
                setFlashcardSet(data.flashcards); // Assuming the flashcards are in a 'flashcards' array
                setTitle(data.title);
                setDescription(data.description);
                setIsLoading(false);
            } catch (err) {
                // setError(err.message);
                setIsLoading(false);
            }
        };

        fetchFlashcardSet();
    }, [backendUrl, sid]);

    const handleNext = () => {
        if (currIndex < flashcardSet.length - 1) {
            setCurrIndex(currIndex + 1);
            // flip back if flipped
        }
    };

    const handlePrev = () => {
        if (currIndex > 0) {
            setCurrIndex(currIndex - 1);
        }
    };

    // Function to navigate back
    const goBack = () => {
        navigate(-1); // Navigate back
    };

    return (
        <div>
            <Sidebar/>
            <main className="py-10 px-5">
                <div className="lg:ml-72 rounded-t-xl p-5  bg-gray-500/5
                shadow mb-5 ring-1 ring-gray-200">
                    {title ? (

                        <div className="px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl border-b border-gray-200 pb-2 font-bold text-gray-900 text-center">
                                {/*font-bold text-gray-900 text-center">*/}
                                {title}
                            </h1>
                            <p className="ml-2 mt-1 truncate text-sm text-center text-gray-500">{description}</p>
                        </div>

                    ) : (
                        <div className="px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center">
                            <SkeletonElement type="title p-3 mx-10 w-1/2 "/>
                            <SkeletonElement type="title p-4 mx-10 mt-1 w-3/4"/>
                        </div>
                    )}
                </div>

                {/*// <div className="lg:ml-72 border-b border-gray-200 pb-5 text-center">*/}
                {/*// <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">*/}
                {/*// <h3 className="ml-2 mt-2 text-base font-semibold leading-6 text-gray-900">{title}</h3>*/}
                {/*// </div>*/}
                {/*// </div>*/}
                {/*) : (*/}
                {/*<SkeletonElement type="title lg:ml-72"/>*/}
                {/*)}*/}


                <div
                    className="lg:ml-72 rounded-xl p-2 sm:p-10 flex justify-center items-center
                        min-h-[85vh] bg-gray-500/5
                         shadow mb-5 ring-1  ring-gray-200"
                >
                    {/*/!* Back button *!/*/}
                    {/*<button*/}
                    {/*    onClick={goBack}*/}
                    {/*    className={`absolute hidden sm:flex left-4 ${*/}
                    {/*        title ? "top-[7.70rem]" : "top-[6.5rem]"*/}
                    {/*    } sm:left-4 lg:left-[19rem]  text-gray-800 px-4 py-2 rounded-md`}*/}
                    {/*>*/}
                    {/*    <svg*/}
                    {/*        xmlns="http://www.w3.org/2000/svg"*/}
                    {/*        height="32"*/}
                    {/*        width="32"*/}
                    {/*        fill="currentColor"*/}
                    {/*        className="bi bi-arrow-return-left"*/}
                    {/*        viewBox="0 0 16 16"*/}
                    {/*    >*/}
                    {/*        <path*/}
                    {/*            fillRule="evenodd"*/}
                    {/*            d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"*/}
                    {/*        />*/}
                    {/*    </svg>*/}
                    {/*</button>*/}

                    <div className="flex items-center justify-center w-full p-4 transition-transform duration-500"
                         style={{transition: "transform 0.4s",}}>
                        <Flashcard
                            className="w-full max-w-sm sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl
                             mx-4 h-auto p-4 focus:outline-none"

                            handleNext={handleNext}
                            handlePrev={handlePrev}
                            next={currIndex < flashcardSet.length - 1}
                            prev={currIndex > 0}
                            frontText={
                                flashcardSet[currIndex] && (flashcardSet[currIndex].frontText || flashcardSet[currIndex].title)
                            }
                            backText={
                                flashcardSet[currIndex] && (flashcardSet[currIndex].backText || flashcardSet[currIndex].content)
                            }
                            backColor={
                                flashcardSet[currIndex]
                                    ? flashcardSet[currIndex].backBgColor
                                    : "#ffffff"
                            }
                            frontColor={flashcardSet[currIndex] ? flashcardSet[currIndex].frontBgColor : "#ffffff"}
                            backFileUrl={
                                flashcardSet[currIndex]
                                    ? flashcardSet[currIndex].backFileUrl
                                    : ""
                            }
                            backFileName={
                                flashcardSet[currIndex]
                                    ? flashcardSet[currIndex].backFileName
                                    : ""
                            }
                            isFlipped={isFlipped}
                            setIsFlipped={setIsFlipped}
                            cardId={flashcardSet[currIndex] ? flashcardSet[currIndex]._id : ""}
                            isPlayMode={true}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default PlayPage;
