import {useState, useContext, useEffect} from "react";
import {Dialog} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import { useLocation} from "react-router-dom";
import Login from "../components/Login";
import {UserContext} from "../App";

export default function LandingPage() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const {user, loadingUser} = useContext(UserContext);
    const location = useLocation(); // Hook from React Router to access location object

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const openLogin = searchParams.get('openLogin'); // 'true', 'false', or null

        if (openLogin === 'true') {
            setLoginModalOpen(true);
        }
    }, [location.search]);

    const navigation = [
        // {
        //     name: "Dashboard",
        //     isBtn: true,
        //     onClick: () => {
        //         if (user) {
        //             window.location.href = "/dashboard";
        //         } else {
        //             setLoginModalOpen(true);
        //         }
        //     },
        // },
        {
            name: "My Flashcards",
            isBtn: true,
            onClick: () => {
                if (user) {
                    window.location.href = "/flashcards/my-cards";
                } else {
                    setLoginModalOpen(true);
                }
            },
        },
        {name: "Flashcards Hub", href: "/flashcards/hub"},
        //   { name: 'Company', href: '#' },
    ];

    // if (loadingUser) {
    //     return <div>Loading...</div>;
    // }

    return (
        <>
            <div className="">
                <header className="absolute inset-x-0 top-0 z-50">
                    <nav
                        className="flex items-center justify-between p-6 lg:px-8"
                        aria-label="Global"
                    >
                        <div className="flex lg:flex-1">
                            <a
                                href="/"
                                className="-m-1.5 p-1.5"
                            >
                                <span className="sr-only">Quizify</span>
                                <img
                                    className={`h-8 w-auto rounded-md  ${
                                        loginModalOpen
                                            ? "filter grayscale opacity-60"
                                            : "shadow-sm ring-1 ring-black  ring-opacity-10"
                                    }`}
                                    src={`${process.env.PUBLIC_URL}/favicon.ico`}
                                    alt="favicon"
                                />
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            {navigation.map((item) =>
                                item.isBtn ? (
                                    <button
                                        key={item.name}
                                        onClick={item.onClick}
                                        className="text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        {item.name}
                                    </button>
                                ) : (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        {item.name}
                                    </a>
                                )
                            )}
                        </div>
                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                            <button
                                onClick={() => setLoginModalOpen(true)}
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                Log in
                            </button>
                            <button
                                onClick={() => (window.location.href = "/signup")}
                                className="text-sm font-semibold leading-6 text-gray-900 ml-5"
                            >
                                Sign up
                            </button>
                        </div>
                    </nav>
                    <Dialog
                        as="div"
                        className="lg:hidden"
                        open={mobileMenuOpen}
                        onClose={setMobileMenuOpen}
                    >
                        <div className="fixed inset-0 z-50"/>
                        <Dialog.Panel
                            className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                            <div className="flex items-center justify-between">
                                <a
                                    href="/"
                                    className="-m-1.5 p-1.5"
                                >
                                    <span className="sr-only">Quizify</span>
                                    <img
                                        className="h-8 w-auto rounded-md shadow-sm ring-1 ring-black ring-opacity-5"
                                        src={`${process.env.PUBLIC_URL}/favicon.ico`}
                                        alt="favicon"
                                    />
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6">
                                        {navigation.map((item) =>
                                            item.isBtn ? (
                                                <button
                                                    key={item.name}
                                                    onClick={() => {
                                                        setMobileMenuOpen(false);
                                                        item.onClick();
                                                    }
                                                    }
                                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                >
                                                    {item.name}
                                                </button>
                                            ) : (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                >
                                                    {item.name}
                                                </a>
                                            )
                                        )}
                                    </div>
                                    <div className="py-1">
                                        <button
                                            onClick={() => {
                                                setMobileMenuOpen(false);
                                                setLoginModalOpen(true);
                                            }}
                                            className="-mx-3 block rounded-lg px-1 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            Log in
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </header>

                <div className="relative isolate pt-14">
                    <svg
                        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path
                                    d="M100 200V.5M.5 .5H200"
                                    fill="none"
                                />
                            </pattern>
                        </defs>
                        <svg
                            x="50%"
                            y={-1}
                            className="overflow-visible fill-gray-50"
                        >
                            <path
                                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect
                            width="100%"
                            height="100%"
                            strokeWidth={0}
                            fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                        />
                    </svg>
                    <div
                        className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Elevate Your Learning with Quizify Flashcards
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Discover the power of personalized flashcards on Quizify.
                                Create, study, and share your way to academic success. Explore a
                                world of knowledge in the Flashcards Hub.
                            </p>
                            <div className="mt-10 flex items-center gap-x-6">
                                <button
                                    onClick={() => {
                                        if (user) {
                                            window.location.href = "/flashcards/hub";
                                        } else {
                                            setLoginModalOpen(true);
                                        }
                                    }}
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Start Learning
                                </button>
                                <a
                                    href="/flashcards/hub"
                                    className="text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Visit the Hub <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                        <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                            <div className="rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10">
                                <img
                                    src={`${process.env.PUBLIC_URL}/page.png`}
                                    alt=""
                                    className="p-2 rounded-xl mx-auto w-[35rem] sm:w-[40rem] max-w-full"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                as="div"
                open={loginModalOpen}
                onClose={() => setLoginModalOpen(false)}
            >
                <div
                    className="fixed inset-0 bg-black bg-opacity-30"
                    aria-hidden="true"
                />
                <div className="fixed inset-0 flex items-center justify-center rounded-xl shadow-xl">
                    <Dialog.Panel className="rounded-xl">
                        <Login/>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </>
    );
}
