import React, {useEffect, useState} from 'react';

export default function SetDetailsForm({onSubmit}) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (localStorage.getItem("cardSetName")) {
            setName(localStorage.getItem("cardSetName"));
        }
        if (localStorage.getItem("cardSetDescription")) {
            setDescription(localStorage.getItem("cardSetDescription"));
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem('cardSetName', name);
        localStorage.setItem('cardSetDescription', description);
        onSubmit({name, description});
    }

    // eslint-disable-next-line react/jsx-no-comment-textnodes
    return (<>
            <div className="alert alert-info mb-10" role="alert">
                <h2 className="text-lg font-medium text-gray-900">Create a new flashcard set</h2>
                <p className="text-sm text-gray-500">Enter the name and description of your new flashcard set.</p>
            </div>

            <div>
                <div>
                    <label htmlFor="set-name" className="block text-sm font-medium text-gray-700">
                        Set Name
                    </label>
                    <input
                        type="text"
                        name="set-name"
                        id="set-name"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                    />
                </div>

                <div className="mt-4">
                    <label htmlFor="set-description" className="block text-sm font-medium text-gray-700">
                        Description
                    </label>
                    <textarea
                        id="set-description"
                        name="set-description"
                        rows={4}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                </div>


                <div className="mt-4">
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent
                    bg-indigo-600 py-2 px-4 border-indigo-600 text-base font-medium text-white
                    hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-indigo-500 sm:text-sm"
                        onClick={handleSubmit}
                    >
                        Next
                    </button>

                </div>
            </div>
        </>
    );
}


