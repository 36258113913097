// useFlip.js
import { useState, useEffect } from 'react';

const useFlip = (numberOfCards, afterFlip) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(false); // Control animation

    useEffect(() => {
        setIsFlipped(false);
        // Prevent animation on initial render or when the number of cards changes
        setShouldAnimate(false);
    }, [numberOfCards]); // Removed afterFlip from dependency array to avoid flips on every afterFlip change

    const toggleFlip = () => {
        setShouldAnimate(true); // Enable animation only on user action
        setIsFlipped(!isFlipped);
    };

    return { isFlipped, toggleFlip, shouldAnimate };
};

export default useFlip;
