import {Fragment, useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import {Dialog, Menu, Transition} from "@headlessui/react";
import Login from "../components/Login";
import {UserContext} from "../App";
import {Avatar} from "@mui/material";
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import {
    ChevronDownIcon,
    MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Sidebar() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const {sideCurrent, setSideCurrent, user} = useContext(UserContext); // Get the headerHeight from context
    const [loginModalOpen, setLoginModalOpen] = useState(false);

    function createAvatarStr(name) {
        if (!name) return
        // Split the name into an array of words
        const nameParts = name.split(' ');

        // Take the first letter of each of the first three words (or less if there aren't three words)
        const avatarLetters = nameParts.slice(0, 3).map(part => part[0].toUpperCase());

        // Join the letters to form the avatar string
        return avatarLetters.join('');
    }

    console.log("user in sidebar:", user);

    const navigation = [
        // {name: "Dashboard", href: "/dashboard", icon: HomeIcon, id: 0},
        {
            name: "My Flashcards",
            href: "/flashcards/my-cards",
            icon: FolderIcon,
            id: 1,
        },
        {name: "Flashcards-Hub", href: "/flashcards/hub", icon: UsersIcon, id: 2}
        //   { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
        //   { name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false },
        //   { name: 'Reports', href: '#', icon: ChartPieIcon, current: false },
    ];


    const teams = [
        {
            id: 3,
            name: "Reports",
            href: "#",
            icon: ChartPieIcon,
            initial: "R",
            current: false,
        },
        {
            id: 4,
            name: "AI Flashcard Creator",
            href: "#",
            initial: "AI",
            current: false,
        },
        //   { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
    ];
    const userNavigation = [
        {name: "Your profile", href: "/"},
        {
            name: "Sign out",
            // href: "/",
            onClick: () => {
                localStorage.clear();
                window.location.href = "/";
            },
        },
    ];

    return (
        <div>
            <Transition.Root
                show={sidebarOpen}
                as={Fragment}
            >
                <Dialog
                    as="div"
                    className="relative z-50 lg:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div
                                    className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-8 w-auto"
                                            src={`${process.env.PUBLIC_URL}/favicon.ico`}
                                            // src="./favicon.ico"
                                            alt="flashcards logo"
                                            onClick={() => window.location.href = "/"}
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul
                                            role="list"
                                            className="flex flex-1 flex-col gap-y-7"
                                        >
                                            <li>
                                                <ul
                                                    role="list"
                                                    className="-mx-2 space-y-1"
                                                >
                                                    {navigation.map((item) => (
                                                        <li key={item.name}>
                                                            <Link
                                                                to={item.href}
                                                                onClick={() => setSideCurrent(item.id)}
                                                                className={classNames(
                                                                    sideCurrent === item.id
                                                                        ? "bg-gray-800 text-white"
                                                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                                )}
                                                            >
                                                                <item.icon
                                                                    className="h-6 w-6 shrink-0"
                                                                    aria-hidden="true"
                                                                />
                                                                {item.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li>
                                                <div className="text-xs font-semibold leading-6 text-gray-400">
                                                    Premium features
                                                </div>
                                                {/* prev: Your teams */}
                                                <ul
                                                    role="list"
                                                    className="-mx-2 mt-2 space-y-1"
                                                >
                                                    {teams.map((team) => (
                                                        <li key={team.name}>
                                                            <a
                                                                onClick={() => {
                                                                    setSideCurrent(team.id);
                                                                    // setShouldNavigate(true);
                                                                }}
                                                                // href={team.href}
                                                                className={classNames(
                                                                    sideCurrent === team.id
                                                                        ? "bg-gray-800 text-white"
                                                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                                )}
                                                            >
																<span
                                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
																	{team.initial}
																</span>
                                                                <span className="truncate">{team.name}</span>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li className="mt-auto">
                                                <a
                                                    href="#"
                                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                                >
                                                    <Cog6ToothIcon
                                                        className="h-6 w-6 shrink-0"
                                                        aria-hidden="true"
                                                    />
                                                    Settings
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                        <img
                            className=" h-9 w-auto rounded-lg"
                            src={`${process.env.PUBLIC_URL}/favicon.ico`}
                            alt="flashcards logo"
                        />
                        {/*       font-family: "McLaren", cursive; font-weight: 200; */}
                        <div className="ms-3 text-white font-mclaren text-2xl">Quizify</div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                        >
                            <li>
                                <ul
                                    role="list"
                                    className="-mx-2 space-y-1"
                                >
                                    {navigation.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                to={item.href}
                                                onClick={() => setSideCurrent(item.id)}
                                                className={classNames(
                                                    sideCurrent === item.id
                                                        ? "bg-gray-800 text-white"
                                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                )}
                                            >
                                                <item.icon
                                                    className="h-6 w-6 shrink-0"
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <div className="text-xs font-semibold leading-6 text-gray-400">
                                    {/* prev: Your teams */}
                                    Premium features
                                </div>
                                <ul
                                    role="list"
                                    className="-mx-2 mt-2 space-y-1"
                                >
                                    {teams.map((team) => (
                                        <li key={team.name}>
                                            <a
                                                onClick={() => setSideCurrent(team.id)}
                                                // href={team.href}
                                                className={classNames(
                                                    sideCurrent === team.id
                                                        ? "bg-gray-800 text-white"
                                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                )}
                                            >
												<span
                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
													{team.initial}
												</span>
                                                <span className="truncate">{team.name}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="mt-auto">
                                <a
                                    href="#"
                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                >
                                    <Cog6ToothIcon
                                        className="h-6 w-6 shrink-0"
                                        aria-hidden="true"
                                    />
                                    Settings
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="lg:pl-72">
                <div
                    className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                    <button
                        type="button"
                        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon
                            className="h-6 w-6"
                            aria-hidden="true"
                        />
                    </button>

                    {/* Separator */}
                    <div
                        className="h-6 w-px bg-gray-900/10 lg:hidden"
                        aria-hidden="true"
                    />

                    <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                        <form
                            className="relative flex flex-1"
                            action="#"
                            method="GET"
                        >
                            <label
                                htmlFor="search-field"
                                className="sr-only"
                            >
                                Search
                            </label>
                            <MagnifyingGlassIcon
                                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                                aria-hidden="true"
                            />
                            <input
                                id="search-field"
                                className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                placeholder="Search..."
                                type="search"
                                name="search"
                            />
                        </form>
                        <div className="flex items-center gap-x-4 lg:gap-x-6">
                            <button
                                type="button"
                                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">View notifications</span>
                                <BellIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>

                            {/* Separator */}
                            <div
                                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                                aria-hidden="true"
                            />

                            {/* Profile dropdown */}
                            {user ? (<Menu
                                as="div"
                                className="relative"
                            >
                                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                    <span className="sr-only">Open user menu</span>
                                    {/* <img
										className="h-8 w-8 rounded-full bg-gray-50"
										src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
										alt=""
									/> */}
                                    <Avatar>{createAvatarStr(user?.username)}</Avatar>
                                    <span className="hidden lg:flex lg:items-center">
										<span
                                            className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                            aria-hidden="true"
                                        >
											{user?.username}
										</span>
										<ChevronDownIcon
                                            className="ml-2 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
									</span>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        <Menu.Item>
                                            {({active}) => (
                                                <a
                                                    href={"/profile"}
                                                    className={classNames(
                                                        active ? "bg-gray-50" : "",
                                                        "block px-3 py-1 text-sm leading-6 text-gray-900"
                                                    )}
                                                >
                                                    Your profile
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({active}) => (
                                                <button
                                                    onClick={() => {
                                                        localStorage.clear();
                                                        window.location.href = "/";
                                                    }}
                                                    className={classNames(
                                                        active ? "bg-gray-50" : "",
                                                        "block px-3 py-1 text-sm leading-6 text-gray-900"
                                                    )}
                                                >
                                                    Sign out
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>) : (
                                <Menu
                                    as="div"
                                    className="relative"
                                >
                                    <Menu.Button
                                        onClick={() => window.location.href = "/?openLogin=true"}
                                        className="-m-1.5 flex items-center p-1.5"
                                    >
                                        <span className="sr-only">Open user menu</span>
                                        <Avatar>{createAvatarStr(user?.username)}</Avatar>
                                        <span className="hidden lg:flex lg:items-center">
                                                                                    <span
                                                                                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                                                                        aria-hidden="true"
                                                                                    >
                                                                                                                                    Login
                                        </span>
                                        </span>
                                    </Menu.Button>
                                </Menu>
                            )}


                        </div>
                    </div>
                </div>

                {/* <main className="py-10">
						<div className="px-4 sm:px-6 lg:px-8">
							{/* Your content }
							</div>
					</main> */}
            </div>


            <Dialog
                as="div"
                open={loginModalOpen}
                onClose={() => setLoginModalOpen(false)}
            >
                <div
                    className="fixed inset-0 bg-black bg-opacity-30"
                    aria-hidden="true"
                />
                <div className="fixed inset-0 flex items-center justify-center rounded-xl shadow-xl">
                    <Dialog.Panel className="rounded-xl">
                        <Login/>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </div>
    );
}
